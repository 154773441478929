let env = 'staging';		// production development staging
// let whitelabel = 1234567891;
let whitelabel = 1234567890;
// let domain = 'https://apiweb.posfin.id/';
let domain = 'https://fintech-stg.bedigital.co.id//';
// let agen = 'https://agen.posfin.id/';
let agen = 'http://localhost:3005/';
let master_referal_default = 'PG7PEQ'; //
let heksa_url = "https://heksainsurance.co.id/doa/Home";
let emeterai_url = "https://emeterai.posfin.id/agen/login";
let edc_url = "https://pospayagen.posfin.id/daftar_edc/";
let mpn_productid = "8098";
let cash_in_productid = "99990";
let ga = { 
	prop : 'UA-97556580-2',
	event : {
		payment : 'tx_payment',
		purchase : 'tx_purchase',
		inquiry : 'tx_inquiry',
		login : 'login'
	}
};

// http://103.58.146.64/doa/posfin


if (env === 'production') {
	whitelabel = 1234567891;
	domain = 'https://apiweb.posfin.id/';
	agen = 'https://agen.posfin.id/';
	master_referal_default = 'PIJ45V';
	heksa_url = "https://heksainsurance.co.id/doa/Home";
}
// if (env === 'production') {
// 	whitelabel = 1234567890;
// 	domain = 'https://fintech-stg.bedigital.co.id/';
// 	agen = 'http://localhost:3005/';
// 	master_referal_default = 'PG7PEQ';
// 	heksa_url = "https://heksainsurance.co.id/doa/Home";
// }
 else if (env === 'development') {
	whitelabel = 1234567890;
	domain = 'https://fintech-stg.bedigital.co.id/';
	agen = 'http://localhost:3005/';
	master_referal_default = 'PG7PEQ';
	heksa_url = "https://heksainsurance.co.id/doa/Home";
} else if (env === 'staging') {
	whitelabel = 1234567890;
	domain = 'https://fintech-stg.bedigital.co.id/';
	agen = 'http://localhost:3005/';
	master_referal_default = 'PG7PEQ';
	heksa_url = "https://heksainsurance.co.id/doa/Home";
}

const config = {
	whitelabel : whitelabel,
	language : "id",
	platform : "web",
	app_version : "v.1.15 surcharge - ",
	user_agent : "web/1.0.0",
	timeout_message : "Transaksi tidak dapat response dari server. Cek Halaman History Transaksi untuk memastikan transaksi Anda sudah diproses dan hubungi Customer Service kami untuk pertanyaan lebih lanjut.",
	helpdesk_email : "helpdesk@posfin.id",
	admin_email : "admin@posfin.id",
	privacy_url : domain + "api/legal/privacy",
	faq_url : domain + "api/legal/faq",
	terms_url : domain + "api/legal/terms",
	posfin_desktop_url : "https://www.dropbox.com/s/14ubq208rnbl112/posfin-windows-v1.2.4.rar?dl=1",
	posfin_desktop_name : "Desktop v1.2.4",
	info_posfin : "[INFO POSFIN] Jangan berikan password dan kode verifikasi masuk POSFIN kamu kepada siapapun. Selalui perbarui password POSFIN secara berkala. Laporkan segala hal yang mencurigakan kepada helpdesk POSFIN",
	master_referal_default : master_referal_default,
	agen_domain : agen,
	ga : ga,
	env : env,
	heksa_url: heksa_url,
	emeterai_url: emeterai_url,
	edc_url: edc_url,
	mpn_productid: mpn_productid,
	cash_in_productid : cash_in_productid
};

export default config;