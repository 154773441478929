import { connect } from 'react-redux'
import React, { Component } from 'react'
import moment from 'moment'
import request from 'superagent'
import MetronicIndex from '../layout/metronic/index'
import { Form, FormGroup } from '../component/form/form'
import { Portlet, PortletHead, PortletBody, PortletFooter, PortletTab, PortletTabHeader, PortletTabHeaderList, PortletTabBody, PortletTabBodyList, PortletAlert, PortletStruk } from '../component/widget/portlet'

import apis from '../helper/api'
import localdata from '../helper/localdata'
import action from '../helper/action'
import do_action from '../action'
import config from '../helper/config'
import swal from 'sweetalert'
import Modal from 'react-responsive-modal'
import util from '../helper/util'
import CetakTagihan from '../component/form/cetak_inquiry'
import CetakStruk from '../component/form/cetak_struk'
import ReactGA from 'react-ga'

let tx_product_postpaid = ""
let tx_product_prepaid = ""
let start_time_process_seconds = 0

class AgentPLN extends Component {
    constructor(props) {
        super(props)
        this.state = {
            no_pelanggan: "",
            no_pelanggan_prepaid: "",
            tx_denom_prepaid: "",
            jenis_postpaid: "",
            denom_prepaid: [],
            product_postpaid: [],
            tx_show_modal: false,
            tx_show_modal_prepaid: false,
            tx_show_struk: false,
            tx_tagihan_prepaid: "",
            tx_tagihan_pelanggan: "",
            tx_flag_processing: false,
            tx_flag_inquiry_processing: false,
            tx_result_message: "",
            tx_result_success: "",
            show_info_pelanggan: false, 
            nama_pelanggan: "", 
            nomor_pelanggan: "",
            show_btn_bayar: true,
            konsumen: false,
            mitra: false,
            tx_tagihan_surcharge: 0,
            tx_button_ok: false,
            konsumen: false,
            mitra: false,
            tx_show_surcharge_postpaid:false,
            tx_show_btn_surcharge_postpaid:false,
            tx_show_btn_surcharge_prepaid:false,
            tx_show_btn_beli:true,
            tx_show_btn_submit:true,
            surcharge:"",
            surchargeprepaid:"",            
            tx_flag_processing_surchargepostpaid: false,
            tx_flag_processing_surchargeprepaid: false

        }
        this.on_btn_tagihan = this.on_btn_tagihan.bind(this)
        this.on_btn_submit_prepaid = this.on_btn_submit_prepaid.bind(this)
        this.on_btn_reset = this.on_btn_reset.bind(this)
        this.on_btn_confirm_cancel = this.on_btn_confirm_cancel.bind(this)
        this.on_btn_confirm_ok = this.on_btn_confirm_ok.bind(this)
        this.on_btn_confirm_ok_prepaid = this.on_btn_confirm_ok_prepaid.bind(this)
        this.on_btn_struk_cancel = this.on_btn_struk_cancel.bind(this)
        this.on_btn_struk_ok = this.on_btn_struk_ok.bind(this)
        this.on_btn_cetak_struk = this.on_btn_cetak_struk.bind(this)
        this.total_bayar_prepaid = this.total_bayar_prepaid.bind(this)
        this.on_key_press = this.on_key_press.bind(this)
        this.on_grouped_change = this.on_grouped_change.bind(this) 
        this.on_btn_surchargeprepaid = this.on_btn_surchargeprepaid.bind(this) 
        this.on_btn_surcharge = this.on_btn_surcharge.bind(this) 

    }

    componentDidMount() {
        let self = this
        this.props.update_current_page('pln')
        tx_product_prepaid = ""
        localdata.get_denom_by_product("SERVICE_PLN_PREPAID", "PRODUCT_PLN_PREPAID").then((payload) => {
            util.log("SERVICE_PLN_PREPAID: ", payload)
            if (payload.length !== 0) {
                self.setState({denom_prepaid : payload})
            }
        }).catch((err) => {
            util.log("error : ", err)
        })
        localdata.get_product("SERVICE_PLN_POSTPAID").then((payload) => {
            util.log("SERVICE_PLN_POSTPAID: ", payload)
            if (payload.length !== 0) {
                self.setState({product_postpaid : payload})
            }
        }).catch((err) => {
            util.log("error : ", err)
        })
    }

    on_grouped_change(e) {       
        try {
          if (e.target.name === 'konsumen') {
            this.satu = e.target.checked
            this.setState({konsumen: e.target.checked});
 
        }
        if (e.target.name === 'mitra') {
             this.setState({mitra: e.target.checked});
            this.dua = e.target.checked
        }
         this.status = (this.satu === true && this.dua === true) ? true : false;
         this.setState({tx_button_ok: this.status});
       
    } catch(err) {
        util.log("[on_grouped_change] error", err)
    }
    }

 
    on_input_change(e) {
        if (e.target.name == "no_pelanggan") {
            this.setState({no_pelanggan: e.target.value})
        } else if (e.target.name == "no_pelanggan_prepaid") {
            this.setState({no_pelanggan_prepaid: e.target.value})
        } else {
            this.setState({[e.target.name]: e.target.value})
        }
    }

    on_key_press(e) {
        util.log(e)
        if (e.keyCode == 13) {
            if (e.target.name == "no_pelanggan") {
                this.on_btn_tagihan()
            } else if (e.target.name == "no_pelanggan_prepaid") {
                this.on_btn_submit_prepaid()
            }
        }
    }

    on_btn_cetak_struk(el) {
        util.log("on_btn_cetak_struk");
        if (this.state.tx_result_success.length !== 0) {
            util.log(this.state.tx_result_success.data);
            if (util.is_electron()) {
                const btn = el.target;
                btn.classList.add("m-loader", "m-loader--primary", "m-loader--right");
                setTimeout(function() {
                    btn.classList.remove("m-loader", "m-loader--primary", "m-loader--right");
                }, 3000);
                const agent_info = this.props.profile;
                util.electron_print_receipt(agent_info, this.state.tx_result_success.data);
            } else {
                this.setState({tx_show_struk: true});
            }
        }
    }

    on_btn_struk_ok() {
        this.setState({tx_show_struk: false})
    }

    on_btn_struk_cancel() {
        this.setState({tx_show_struk: false})
    }

    
    
    on_btn_surcharge() {        
        if (this.state.no_pelanggan.length !== 0) {

            this.setState({tx_show_modal: false,tx_show_surcharge_postpaid: true,tx_show_btn_beli:false});        
        }
    }
    
    
    on_btn_surchargeprepaid() {        
        if (this.state.no_pelanggan_prepaid.length !== 0) {

            this.setState({tx_show_modal: false,tx_show_surcharge_prepaid: true,tx_show_btn_beli:false});        
        }
    }


    on_select_change(e) {
        if (e.target.value.length !== 0) {
            let product_name = e.target[e.target.selectedIndex].getAttribute('data-product_name')
            let surcharge = e.target[e.target.selectedIndex].getAttribute('data-surcharge')
            tx_product_postpaid = {
                "product_id" : e.target.value,
                "product_name" : product_name,
                "max_surcharge":surcharge
            }
            util.log("on_select_change surcharge ", surcharge );

            if(surcharge > 0 )
                {
                    this.setState({tx_show_btn_surcharge_postpaid: true});
                }
                else
                {
                    this.setState({tx_show_btn_surcharge_postpaid: false,tx_show_btn_beli:true,tx_show_surcharge_postpaid:false});
                }   
        } else {
            tx_product_postpaid = ""
        }
             

        util.log("on_select_change: ", e.target.value )
        this.setState({jenis_postpaid: e.target.value})
        this.nomor_pelanggan_postpaid_input.focus()
    }

    on_select_change_prepaid(e) {
        if (e.target.value.length !== 0) {
            let product_id = e.target[e.target.selectedIndex].getAttribute('data-product_id')
            let product_name = e.target[e.target.selectedIndex].getAttribute('data-product_name')
            let max_surcharge = e.target[e.target.selectedIndex].getAttribute('data-max_surcharge')
            tx_product_prepaid = {
                "product_id" : product_id,
                "product_code" : e.target.value,
                "product_name" : product_name,
                "max_surcharge" : max_surcharge, 
            }
            util.log("on_select_change_prepaid", tx_product_prepaid)
            util.log("max_surcharge", max_surcharge)

            if(max_surcharge > 0 )
                {
                    this.setState({tx_show_btn_surcharge_prepaid: true});
                }
                else
                {
                    this.setState({tx_show_btn_surcharge_prepaid: false,tx_show_btn_submit:true,tx_show_surcharge_prepaid:false});
                }   


        } else {
            tx_product_prepaid = ""
        }
        util.log("on_select_change_prepaid: ", e.target.value)
        this.setState({tx_denom_prepaid: e.target.value})
        this.nomor_pelanggan_prepaid_input.focus()
    }

    on_btn_tagihan() {
        if (this.state.tx_flag_inquiry_processing) return
        let self = this
        if (self.state.no_pelanggan.length !== 0) {
            util.log("jenis_postpaid: ", self.state.jenis_postpaid)
            if (self.state.jenis_postpaid.length === 0) {
                swal("Ups!", "Silakan pilih jenis tagihan terlebih dulu!", "warning")
                return
            }
            let biller_number = this.state.no_pelanggan
            let body = {
                "session_id" : self.props.session,
                "product_id" : self.state.jenis_postpaid,
                "biller_number": biller_number,
                "surcharge": this.state.surcharge
            }
            self.setState({tx_flag_inquiry_processing: true, tx_tagihan_pelanggan: "",tx_flag_processing_surchargepostpaid:true})
            start_time_process_seconds = Math.floor(Date.now() / 1000)
            request.post(apis.inquiry)
                .set("Content-Type", "application/json")
                .timeout(120*1000)
                .send(body)
                .end(function(err, res) {
                    let message_error = ""
                    if (err || !res.ok) {
                        util.log(err)
                        message_error = {"body": err.message, "title": "Transaksi Gagal"}
                        if (res != undefined) {
                            message_error.body = "Error code " + res.statusCode
                        }
                    } else {
                        util.log(res);
                        if (res.statusCode == 200) {
                            let payload = res.body
                            util.log(payload)
                            if (payload.status == "OK") {
                                self.setState({tx_tagihan_pelanggan: payload.results, show_btn_bayar: true})
                                if (start_time_process_seconds !== 0) {
                                    let end_time_process = Math.floor(Date.now() / 1000)
                                    let action = config.ga.event.inquiry + '-' + util.time_range_ga(end_time_process-start_time_process_seconds)
                                    ReactGA.event({
                                        category: 'transaction',
                                        action: action,
                                        label: payload.results.product_name
                                    })
                                }
                            } else {
                                util.on_error(payload.error)
                                message_error = {"body": res.body.error.message, "title": "Transaksi Gagal"}
                                if (res.body.display_message !== undefined) {
                                    message_error.title = res.body.error.message
                                    message_error.body = res.body.display_message
                                }
                            }
                        } else {
                            message_error = {"body": "HTTP error " + res.statusCode, "title": "Transaksi Gagal"}
                        }
                    }
                    if (message_error.length != 0) {
                        swal(message_error.title, message_error.body, "error")
                    }
                    self.setState({tx_flag_inquiry_processing: false,tx_flag_processing_surchargepostpaid:false})
                    start_time_process_seconds = 0
                });
        }
    }

    on_btn_reset() {
        this.setState({no_pelanggan: "", denoms: [], tx_result_message: "", tx_result_success: "", tx_tagihan_pelanggan: "",tx_show_btn_surcharge_postpaid:false,tx_show_btn_surcharge_prepaid:false})
        tx_product_prepaid = ""
        tx_product_postpaid = ""
    }

    on_btn_confirm_cancel() {
        // this.setState({tx_show_modal: false, tx_show_modal_prepaid: false})
        this.setState({tx_show_modal: false,tx_show_surcharge:false,surcharge:0,tx_show_btn_beli:true,tx_show_surcharge_postpaid: false,tx_show_modal_prepaid:false,tx_show_btn_surcharge_postpaid:false,tx_show_btn_surcharge_prepaid:false,tx_show_surcharge_prepaid:false})

    }

    on_btn_bayar() {
        this.setState({tx_show_modal: true, show_info_pelanggan: false, nama_pelanggan: "", nomor_pelanggan: ""})
    }

    on_btn_submit_prepaid() {
        if (this.state.no_pelanggan_prepaid.length === 0) {
            swal("Ups!", "Nomor pelanggan tidak boleh kosong", "warning")
            return
        }
        if (this.state.tx_denom_prepaid.length === 0) {
            swal("Ups!", "Pilih denom pelanggan terlebih dulu", "warning")
            return
        }
        this.setState({tx_show_modal_prepaid: true, show_info_pelanggan: false, nama_pelanggan: "", nomor_pelanggan: ""})
        this.total_bayar_prepaid()
    }

    on_btn_confirm_ok() {
        util.log("this.state.tx_flag_processing: ", this.state.tx_flag_processing)
        if (this.state.tx_flag_processing) return
        if (this.state.jenis_postpaid.length !== 0) {
            let self = this
            let whitelabel_36 = config.whitelabel.toString(36).toUpperCase()
            let user_id = this.props.profile.userid.toString(36).toUpperCase()
            let prefix = whitelabel_36.substring(whitelabel_36.length - 3)
            let suffix = user_id.substring(user_id.length - 3)
            let token = util.gen_token(prefix, this.props.session, suffix)
            let biller_number = this.state.no_pelanggan
            let body = {
                "session_id" : this.props.session,
                "product_id" : this.state.jenis_postpaid,
                "biller_number" : biller_number,
                "sign_token" : token,
                "surcharge":this.state.surcharge
            }
            if (this.state.nama_pelanggan !== undefined && this.state.nama_pelanggan.length !== 0) {
                body.customer_name = this.state.nama_pelanggan
            }
            if (this.state.nomor_pelanggan !== undefined && this.state.nomor_pelanggan.length !== 0) {
                body.customer_phone_number = this.state.nomor_pelanggan
            }
            self.setState({tx_flag_processing: true})
            start_time_process_seconds = Math.floor(Date.now() / 1000)
            request.post(apis.payment)
                .set("Content-Type", "application/json")
                .timeout(120*1000)
                .send(body)
                .end(function(err, res) {
                    let message_result = ""
                    let success_result = ""
                    if (err || !res.ok) {
                        util.log(err)
                        message_result = {title: "Error", body: err.message}
                        if (res != undefined) {
                            message_result.body = "Error code " + res.statusCode
                        }
                        if (err.code === 504) {
                            message_result.body = config.timeout_message
                        }
                    } else {
                        util.log(res);
                        if (res.statusCode == 200) {
                            let payload = res.body
                            util.log(payload)
                            if (payload.status == "OK") {
                                let results = payload.results
                                let success_info = "Biller No: " + results.biller_number + "|Produk: " + results.product_id + " " + results.product_name + "|Harga Produk: Rp. " + util.rupiah_format(results.nominal) + "|Biaya Admin: Rp. " + util.rupiah_format(results.fee) + "|Biaya Surcharge: Rp. " + util.rupiah_format(results.surcharge) + "|Total Bayar: Rp. " + util.rupiah_format(results.total) 
                                success_result = {title: "Transaksi Sukses", body: success_info, data: results}
                                self.props.update_balance(results.balance_after)
                                swal(success_result.title, payload.display_message, "success")
                                self.setState({show_btn_bayar:false})
                                if (start_time_process_seconds !== 0) {
                                    let end_time_process = Math.floor(Date.now() / 1000)
                                    let action = config.ga.event.payment + '-' + util.time_range_ga(end_time_process-start_time_process_seconds)
                                    ReactGA.event({
                                        category: 'transaction',
                                        action: action,
                                        label: results.product_name
                                    })
                                }
                            } else {
                                util.on_error(payload.error)
                                message_result = {title: "Error", body: res.body.error.message}
                                if (res.body.display_message != undefined) {
                                    message_result.body = message_result.body + ". " + res.body.display_message
                                }
                            }
                        } else {
                            message_result = {title: "Error", body: res.statusCode}
                        }
                    }
                    if (message_result.length != 0) {
                        swal(message_result.title, message_result.body, "error")
                        message_result.title = "[" + moment().format("DD-MM-YYYY hh:mm") + "] " + message_result.title
                    }
                    self.setState({tx_flag_processing: false, tx_result_message: message_result, tx_result_success: success_result, tx_show_modal: false})
                    start_time_process_seconds = 0
                });
        }
    }

    on_btn_confirm_ok_prepaid() {
        if (this.state.tx_flag_processing) return
        if (tx_product_prepaid !== "") {
            let self = this
            // if(this.state.input_surcharge > tx_product_prepaid.max_surcharge)
            //     { 
            //         swal("warning", "Surharge harus <= "+tx_product_prepaid.max_surcharge, "error");
            //         return false;
    
            //     }

            let whitelabel_36 = config.whitelabel.toString(36).toUpperCase()
            let user_id = this.props.profile.userid.toString(36).toUpperCase()
            let prefix = whitelabel_36.substring(whitelabel_36.length - 3)
            let suffix = user_id.substring(user_id.length - 3)
            let token = util.gen_token(prefix, this.props.session, suffix)
            let biller_number = this.state.no_pelanggan_prepaid
            let body = {
                "session_id" : this.props.session,
                "product_id" : tx_product_prepaid.product_id,
                "product_code" : tx_product_prepaid.product_code,
                "biller_number" : biller_number,
                "sign_token" : token,
                "surcharge":this.state.surchargeprepaid
            }
            if (this.state.input_surcharge !== undefined && this.state.input_surcharge.length !== 0) {
                body.input_surcharge = this.state.input_surcharge
            }
            if (this.state.nama_pelanggan !== undefined && this.state.nama_pelanggan.length !== 0) {
                body.customer_name = this.state.nama_pelanggan
            }
            if (this.state.nomor_pelanggan !== undefined && this.state.nomor_pelanggan.length !== 0) {
                body.customer_phone_number = this.state.nomor_pelanggan
            }
            self.setState({tx_flag_processing: true})
            start_time_process_seconds = Math.floor(Date.now() / 1000)
            request.post(apis.purchase)
                .set("Content-Type", "application/json")
                .timeout(120*1000)
                .send(body)
                .end(function(err, res) {
                    let message_result = ""
                    let success_result = ""
                    if (err || !res.ok) {
                        util.log(err)
                        message_result = {title: "Error", body: err.message}
                        if (res != undefined) {
                            message_result.body = "Error code " + res.statusCode
                        }
                        if (err.code === 504) {
                            message_result.body = config.timeout_message
                        }
                    } else {
                        util.log(res);
                        if (res.statusCode == 200) {
                            let payload = res.body
                            util.log(payload)
                            if (payload.status == "OK") {
                                let results = payload.results
                                let success_info = "Biller No: " + results.biller_number + "|Produk: " + results.product_id + " " + results.product_name + "|Harga Produk: Rp. " + util.rupiah_format(results.nominal) + "|Biaya Admin: Rp. " + util.rupiah_format(results.fee) + "|Biaya Surcharge: Rp. " + util.rupiah_format(results.surcharge) + "|Total Bayar: Rp. " + util.rupiah_format(results.total) 
                                success_result = {title: "Transaksi Sukses", body: success_info, data: results}
                                self.props.update_balance(results.balance_after)
                                if (start_time_process_seconds !== 0) {
                                    let end_time_process = Math.floor(Date.now() / 1000)
                                    let action = config.ga.event.purchase + '-' + util.time_range_ga(end_time_process-start_time_process_seconds)
                                    ReactGA.event({
                                        category: 'transaction',
                                        action: action,
                                        label: results.product_name
                                    })
                                }
                            } else {
                                util.on_error(payload.error)
                                message_result = {title: "Error", body: res.body.error.message}
                                if (res.body.display_message != undefined) {
                                    message_result.body = message_result.body + ". " + res.body.display_message
                                }
                            }
                        } else {
                            message_result = {title: "Error", body: res.statusCode}
                        }
                    }
                    if (message_result != null && message_result.length != 0) {
                        swal(message_result.title, message_result.body, "error")
                        message_result.title = "[" + moment().format("DD-MM-YYYY hh:mm") + "] " + message_result.title
                    }
                    self.setState({tx_flag_processing: false, tx_result_message: message_result, tx_result_success: success_result, tx_show_modal_prepaid: false})
                    start_time_process_seconds = 0
                });
        }
    }

    total_bayar_prepaid() {
        util.log("total_bayar_prepaid start");

        let biller_number = this.state.no_pelanggan_prepaid
        let surchargeprepaid = this.state.surchargeprepaid
        let self = this
        let body = {
            "session_id" : self.props.session,
            "product_id" : tx_product_prepaid.product_id,
            "product_code" : tx_product_prepaid.product_code,
            "biller_number": biller_number,
            "surcharge": surchargeprepaid 
        }
        self.setState({tx_tagihan_prepaid: "",tx_flag_processing_surchargeprepaid:true})
        request.post(apis.inquiry)
            .set("Content-Type", "application/json")
            .timeout(120*1000)
            .send(body)
            .end(function(err, res) {
                let tagihan = ""
                if (err || !res.ok) {
                    util.log(err)
                    tagihan = "-"
                } else {
                    util.log(res);
                    if (res.statusCode == 200) {
                        let payload = res.body
                        util.log(payload)
                        if (payload.status == "OK") {
                            let results = payload.results
                            tagihan = {
                                "nominal" : 'Rp. ' + util.rupiah_format(results.nominal),
                                "fee" : 'Rp. ' + util.rupiah_format(results.fee),
                                "surcharge" : 'Rp. ' + util.rupiah_format(results.surcharge),
                                "total" : 'Rp. ' + util.rupiah_format(results.total),
                                "info" : results.info
                            }
                            if (start_time_process_seconds !== 0) {
                                let end_time_process = Math.floor(Date.now() / 1000)
                                let action = config.ga.event.inquiry + '-' + util.time_range_ga(end_time_process-start_time_process_seconds)
                                ReactGA.event({
                                    category: 'transaction',
                                    action: action,
                                    label: results.product_name
                                })
                            }
                        } else {
                            util.on_error(payload.error)
                            tagihan = "-"
                            if (payload.display_message.length !== 0) {
                                tagihan = payload.display_message
                            } else {
                                tagihan = payload.error.message
                            }
                        }
                    } else {
                        tagihan = "-"
                    }
                }
                self.setState({tx_tagihan_prepaid: tagihan,tx_flag_processing_surchargeprepaid:false})
                start_time_process_seconds = 0
            })
    }

    on_btn_cetak_tagihan() {
        this.setState({show_cetak_tagihan: true})
    }

    render() {
        let self = this
        let tx_result_success = this.state.tx_result_success.data
        const padding_field = {paddingTop: 6+'px',paddingBottom: 6+'px'}
        const label_tagihan_style = {textAlign:'left',padding:0,marginTop:'auto',marginBottom:'auto',fontWeight:600}
        const value_tagihan_style = {fontWeight:600,width:'100%',textAlign:'right',padding:0}
        
        
        const SurchargePostpaidView = (props) => 
            <div className="col-lg-6">
                <div className="m-portlet m-portlet--success m-portlet--head-solid-bg m-portlet--rounded" style={{minHeight:'500px',background:'#fff'}}>
                    <div className="m-portlet__head">
                        <div className="m-portlet__head-caption">
                            <div className="m-portlet__head-title">
                                <span className="m-portlet__head-icon">
                                    <i className="flaticon-interface-5"></i>
                                </span>
                                <h3 className="m-portlet__head-text">
                                    Input Surcharge 
                                </h3>
                            </div>
                        </div>
                    </div>
                    <div className="m-portlet__body">
                        <div className="row">
                            <div className="col-lg-12">
                                <form>
                                    <div className="form-group m-form__group">
                                        <span className="m--font-boldest">
                                            No Pelanggan:
                                        </span>
                                        <p className="form-control-static">{this.state.no_pelanggan}</p>
                                    </div>
                                    <div className="form-group">
                                        <span className="m--font-boldest">
                                            Produk ID:
                                        </span>
                                        <p className="form-control-static">{tx_product_postpaid.product_id}</p>
                                    </div>
                                    <div className="form-group">
                                        <span className="m--font-boldest">
                                            Produk:
                                        </span>
                                        <p className="form-control-static">{tx_product_postpaid.product_name}</p>
                                    </div>
                                     <div className="form-group">
                                    <span className="m--font-boldest">Input Surcharge: </span>
                                    <input
                                            onChange={(e) => this.on_input_change(e)}
                                            type="number"
                                            className="form-control m-input"
                                            name="surcharge"
                                            value={this.state.surcharge}
                                            placeholder="Input Surcharge"/> 
                                    <span class="text-danger small bold">Biaya Surcharge untuk produk ini maksimal Rp.{tx_product_postpaid.max_surcharge} <br/> Biaya Surcharge ini belum termasuk PPH 2%<br/>Biaya Surcharge belum Termasuk PPN 11%</span>
                                    <div className="topping">
                                        <label className="m-checkbox m-checkbox--focus" style={{fontWeight: 500,display:'inline-grid',marginLeft:'15px',paddingLeft:'23px'}}>
                                        <input type="checkbox" name="konsumen" id="1" checked={this.state.konsumen} onChange={this.on_grouped_change} />
                                        Konsumen mengetahui dan setuju untuk biaya Surcharge
                                        <span style={{height:'13px',width:'13px'}}></span>
                                        </label>
                                        <label className="m-checkbox m-checkbox--focus" style={{fontWeight: 500,display:'inline-grid',marginLeft:'15px',paddingLeft:'23px'}}>                                            
                                        <input type="checkbox" name="mitra" id="2" checked={this.state.mitra} onChange={this.on_grouped_change} />
                                        Mitra Agen bertanggung jawab atas biaya surcharge pada produk ini                                            <span style={{height:'13px',width:'13px'}}></span>
                                        </label>  
                                    </div>                                    
                                    </div>
                                    <div className=" m-portlet__foot m-portlet__foot--fit" style={{marginTop:'20px'}}>
                                        <div className="m-form__actions">
                                            <div className="row">
                                                <p className="m--font-boldest" style={{marginLeft: 'auto',marginRight: 'auto',marginTop: '10px',textAlign: 'center',fontWeight: 600,fontSize: 'larger'}}>
                                                    Apakah Anda yakin untuk melanjutkan transaksi ini?
                                                </p>
                                                <div style={{margin:'auto'}}>
                                                    <button type="button" onClick={this.on_btn_confirm_cancel} className="btn btn-secondary" data-dismiss="modal">
                                                        Cancel
                                                    </button>
                                                    &nbsp;&nbsp;
                                                    <button disabled={this.state.tx_button_ok ? false : true}  type="button" onClick={this.on_btn_tagihan} className={this.state.tx_flag_processing_surchargepostpaid ? 'btn btn-primary m-loader m-loader--light m-loader--right' : 'btn btn-primary'}>
                                                        Cek tagihan
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        
        const SurchargePrepaidView = (props) => 
            <div className="col-lg-6">
                <div className="m-portlet m-portlet--success m-portlet--head-solid-bg m-portlet--rounded" style={{minHeight:'500px',background:'#fff'}}>
                    <div className="m-portlet__head">
                        <div className="m-portlet__head-caption">
                            <div className="m-portlet__head-title">
                                <span className="m-portlet__head-icon">
                                    <i className="flaticon-interface-5"></i>
                                </span>
                                <h3 className="m-portlet__head-text">
                                    Input Surcharge Prepaid
                                </h3>
                            </div>
                        </div>
                    </div>
                    <div className="m-portlet__body">
                        <div className="row">
                            <div className="col-lg-12">
                                <form>
                                    <div className="form-group m-form__group">
                                        <span className="m--font-boldest">
                                            No Pelanggan:
                                        </span>
                                        <p className="form-control-static">{this.state.no_pelanggan_prepaid}</p>
                                    </div>
                                    <div className="form-group">
                                        <span className="m--font-boldest">
                                            Produk ID:
                                        </span>
                                        <p className="form-control-static">{tx_product_prepaid.product_id}</p>
                                    </div>
                                    <div className="form-group">
                                        <span className="m--font-boldest">
                                            Produk:
                                        </span>
                                        <p className="form-control-static">{tx_product_prepaid.product_name}</p>
                                    </div>
                                     <div className="form-group">
                                    <span className="m--font-boldest">Input Surcharge: </span>
                                    <input
                                            onChange={(e) => this.on_input_change(e)}
                                            type="number"
                                            className="form-control m-input"
                                            name="surchargeprepaid"
                                            value={this.state.surchargeprepaid}
                                            placeholder="Input Surcharge"/> 
                                    <span class="text-danger small bold">Biaya Surcharge untuk produk ini maksimal Rp.{tx_product_prepaid.max_surcharge} <br/> Biaya Surcharge ini belum termasuk PPH 2%<br/>Biaya Surcharge belum Termasuk PPN 11%</span>
                                    <div className="topping">
                                        <label className="m-checkbox m-checkbox--focus" style={{fontWeight: 500,display:'inline-grid',marginLeft:'15px',paddingLeft:'23px'}}>
                                        <input type="checkbox" name="konsumen" id="1" checked={this.state.konsumen} onChange={this.on_grouped_change} />
                                        Konsumen mengetahui dan setuju untuk biaya Surcharge
                                        <span style={{height:'13px',width:'13px'}}></span>
                                        </label>
                                        <label className="m-checkbox m-checkbox--focus" style={{fontWeight: 500,display:'inline-grid',marginLeft:'15px',paddingLeft:'23px'}}>                                            
                                        <input type="checkbox" name="mitra" id="2" checked={this.state.mitra} onChange={this.on_grouped_change} />
                                        Mitra Agen bertanggung jawab atas biaya surcharge pada produk ini                                            <span style={{height:'13px',width:'13px'}}></span>
                                        </label>  
                                    </div>                                    
                                    </div>
                                    <div className=" m-portlet__foot m-portlet__foot--fit" style={{marginTop:'20px'}}>
                                        <div className="m-form__actions">
                                            <div className="row">
                                                <p className="m--font-boldest" style={{marginLeft: 'auto',marginRight: 'auto',marginTop: '10px',textAlign: 'center',fontWeight: 600,fontSize: 'larger'}}>
                                                    Apakah Anda yakin untuk melanjutkan transaksi ini?
                                                </p>
                                                <div style={{margin:'auto'}}>
                                                    <button type="button" onClick={this.on_btn_confirm_cancel} className="btn btn-secondary" data-dismiss="modal">
                                                        Cancel
                                                    </button>
                                                    &nbsp;&nbsp;
                                                    <button disabled={this.state.tx_button_ok ? false : true}  type="button" onClick={this.on_btn_submit_prepaid} className={this.state.tx_flag_processing_surchargeprepaid? 'btn btn-primary m-loader m-loader--light m-loader--right' : 'btn btn-primary'}>
                                                        Submit
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        const TagihanView = (props) => 
        <div className="col-lg-6">
            <div className="m-portlet m-portlet--success m-portlet--head-solid-bg m-portlet--rounded" style={{minHeight:'500px',background:'#fff'}}>
                <div className="m-portlet__head">
                    <div className="m-portlet__head-caption">
                        <div className="m-portlet__head-title">
                            <span className="m-portlet__head-icon">
                                <i className="flaticon-interface-5"></i>
                            </span>
                            <h3 className="m-portlet__head-text">
                                Tagihan PLN POSTPAID {this.state.no_pelanggan}
                            </h3>
                        </div>
                    </div>
                    <div className="m-portlet__head-tools">
                        <ul className="m-portlet__nav">
                            {this.state.show_btn_bayar ? 
                                <li className="m-portlet__nav-item">
                                    <button onClick={() => this.on_btn_bayar()} className="m-portlet__nav-link btn btn-primary m-btn m-btn--pill m-btn--air" style={{padding:"7px 15px"}}>
                                        Bayar
                                    </button>
                                </li>
                            : ''}
                            <li className="m-portlet__nav-item" style={{display:'none'}}>
                                <button onClick={() => this.on_btn_cetak_tagihan()} className="m-portlet__nav-link btn btn-light m-btn m-btn--pill m-btn--air" style={{padding:"7px 15px"}}>
                                    Cetak
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="m-portlet__body" style={{paddingTop: 10+'px'}}>
                    <div className="row">
                        <div className="col-lg-12">
                            <form className="m-form m-form--fit m-form--label-align-right m-form--group-seperator-dashed">
                                <div className="form-group m-form__group row" style={padding_field}>
                                    <label className="col-lg-2 col-form-label" style={label_tagihan_style}>
                                        Produk :
                                    </label>
                                    <div className="col-lg-10" style={{paddingLeft:0,paddingRight:0}}>
                                        <label className="col-form-label" style={{padding:0}}>
                                            {tx_product_postpaid.length !== 0 ? this.state.jenis_postpaid + " " + tx_product_postpaid.product_name : this.state.jenis_postpaid}
                                        </label>
                                    </div>
                                </div>
                                <div className="form-group m-form__group row" style={padding_field}>
                                    <label className="col-lg-2 col-form-label" style={label_tagihan_style}>
                                        Info :
                                    </label>
                                    <div className="col-lg-10" style={{paddingLeft:0,paddingRight:0}}>
                                        <label className="col-form-label" style={{lineHeight:'20px',padding:0}}>
                                            {this.state.tx_tagihan_pelanggan.info.split('|').map(function(item, key) {
                                                return (
                                                    <span key={key}>
                                                        {item}
                                                        <br/>
                                                    </span>
                                                )
                                            })}
                                        </label>
                                    </div>
                                </div>
                                <div className="form-group m-form__group row" style={padding_field}>
                                    <label className="col-lg-4 col-form-label" style={label_tagihan_style}>
                                        Tagihan :
                                    </label>
                                    <div className="col-lg-8">
                                        <label className="col-form-label" style={value_tagihan_style}>
                                            Rp. {this.state.tx_tagihan_pelanggan?util.rupiah_format(this.state.tx_tagihan_pelanggan.nominal):''}
                                        </label>
                                    </div>
                                </div>
                                <div className="form-group m-form__group row" style={padding_field}>
                                    <label className="col-lg-4 col-form-label" style={label_tagihan_style}>
                                        Biaya Admin :
                                    </label>
                                    <div className="col-lg-8">
                                        <label className="col-form-label" style={value_tagihan_style}>
                                            Rp. {this.state.tx_tagihan_pelanggan?util.rupiah_format(this.state.tx_tagihan_pelanggan.fee):'0'}
                                        </label>
                                    </div>
                                </div>
                                <div className="form-group m-form__group row" style={padding_field}>
                                    <label className="col-lg-4 col-form-label" style={label_tagihan_style}>
                                        Total Bayar :
                                    </label>
                                    <div className="col-lg-8">
                                        <label className="col-form-label" style={value_tagihan_style}>
                                            Rp. {this.state.tx_tagihan_pelanggan?util.rupiah_format(this.state.tx_tagihan_pelanggan.total):''}
                                        </label>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        return (
        <React.Fragment>
            <div className="row">
                <div className="col-md-12">
                    {this.state.tx_result_message.length != 0 ? <PortletAlert icon="warning" title={this.state.tx_result_message.title} message={this.state.tx_result_message.body} /> : ""}
                    {this.state.tx_result_success.length != 0 ? <PortletStruk icon="warning" title={this.state.tx_result_success.title} message={this.state.tx_result_success.body} on_btn_cetak_struk={this.on_btn_cetak_struk}/> : ""}
                </div>
            </div>
            <div className="row">
                <div className="col-lg-6">
                    <div className="m-portlet m-portlet--tab">
                        <PortletTab>
                            <PortletTabHeader>
                                    <PortletTabHeaderList id={'pln-postpaid-tab'} title={'PLN POSTPAID'} icon={' la-align-justify'} active />
                                    <PortletTabHeaderList id={'pln-prepaid-tab'} title={'PLN PREPAID'} icon={' la-align-justify'} />
                            </PortletTabHeader>
                            <div className="m-portlet__body" style={{}}>
                                <div className="tab-content">
                                    <PortletTabBodyList id={'pln-postpaid-tab'} active>
                                        <Form>
                                            <label htmlFor="denom" style={{fontWeight: 400}}>Bayar Tagihan</label>
                                            <select className="form-control m-input" id="postpaid-sel" onChange={(e) => this.on_select_change(e)} value={this.state.jenis_postpaid}>
                                            <option key={999} value=""> -- Pilih Jenis Tagihan -- </option>
                                            {
                                                 self.state.product_postpaid.length != 0 ? self.state.product_postpaid.map((product, j) =>
                                                    <option key={j} value={product.product_id} data-product_name={product.product_name} data-surcharge={product.max_surcharge}>
                                                        {product.product_name}
                                                    </option>
                                                ) : ''
                                            }
                                            </select>
                                            <br/>
                                            <label htmlFor="nomor-pelanggan" style={{fontWeight: 400}}>Nomor Pelanggan</label>
                                            <input ref={input => {this.nomor_pelanggan_postpaid_input = input}}
                                                onChange={(e) => this.on_input_change(e)}
                                                onKeyDown={this.on_key_press}
                                                type="text"
                                                className="form-control m-input"
                                                name="no_pelanggan"
                                                value={this.state.no_pelanggan}
                                                placeholder="Nomor Meter"/>
                                            <br/>
                                            <div style={{marginTop:'20px'}}>
                                                <div className="row" style={{marginBottom:'10px'}}>
                                                    <div className="col-12">
                                                    {this.state.tx_show_btn_surcharge_postpaid ? <button onClick={() => this.on_btn_surcharge()} type="button" className="btn btn-primary mr-2">
                                                    Input Surcharge
                                                </button> : ''}
                                                {this.state.tx_show_btn_beli ? 
                                                        <button onClick={() => this.on_btn_tagihan()} type="button" className={this.state.tx_flag_inquiry_processing ? 'btn btn-success m-loader m-loader--light m-loader--right' : 'btn btn-success'}>
                                                            Cek Tagihan
                                                        </button> : '' }
                                                        &nbsp;&nbsp;
                                                        <button onClick={() => this.on_btn_reset()} type="reset" className="btn btn-secondary">
                                                            Reset
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </Form>
                                    </PortletTabBodyList>

                                    <PortletTabBodyList id={'pln-prepaid-tab'}>
                                        <Form>
                                            <label htmlFor="denom" style={{fontWeight: 400}}>Denom</label>
                                            <select className="form-control m-input" id="prepaid-sel" value={this.state.tx_denom_prepaid} onChange={(e) => this.on_select_change_prepaid(e)}>
                                                <option key={999} value=""> -- Pilih Denom -- </option>
                                                {
                                                     self.state.denom_prepaid.length != 0 ? self.state.denom_prepaid.denoms.map((denom, i) =>
                                                        <option key={i} value={denom.product_code} data-product_id={self.state.denom_prepaid.product_id} data-product_name={denom.product_name} data-max_surcharge={denom.max_surcharge}>
                                                            {denom.product_name}
                                                        </option>
                                                    ) : ''
                                                }
                                            </select>
                                            <br/>
                                            <label htmlFor="nomor-meter" style={{fontWeight: 400}}>Nomor Pelanggan</label>
                                            <input
                                                ref={input => {this.nomor_pelanggan_prepaid_input = input}}
                                                onChange={(e) => this.on_input_change(e)}
                                                onKeyDown={this.on_key_press}
                                                type="text"
                                                className="form-control m-input"
                                                name="no_pelanggan_prepaid"
                                                value={this.state.no_pelanggan_prepaid}
                                                placeholder="Nomor Meter"/>
                                            <br/>
                                            <div style={{marginTop:'20px'}}>
                                                <div className="row" style={{marginBottom:'10px'}}>
                                                    <div className="col-12">

                                                    {this.state.tx_show_btn_surcharge_prepaid ? <button onClick={() => this.on_btn_surchargeprepaid()} type="button" className="btn btn-primary mr-2">
                                                    Input Surcharge
                                                </button> : ''}

                                                
                                                {this.state.tx_show_btn_submit ?
                                                        <button onClick={() => this.on_btn_submit_prepaid()} type="button" className="btn btn-success">
                                                            Submit
                                                        </button> : '' }
                                                        &nbsp;&nbsp;
                                                        <button onClick={() => this.on_btn_reset()} type="reset" className="btn btn-secondary">
                                                            Reset
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </Form>
                                    </PortletTabBodyList>
                                </div>
                            </div>
                        </PortletTab>
                    </div>
                </div>
                {this.state.tx_tagihan_pelanggan ? <TagihanView/> : ''}
                {this.state.tx_show_surcharge_postpaid ? <SurchargePostpaidView/> : ''}
                {this.state.tx_show_surcharge_prepaid ? <SurchargePrepaidView/> : ''}

                <Modal className="modal" open={this.state.tx_show_modal} onClose={this.on_btn_confirm_cancel} center showCloseIcon={false} closeOnOverlayClick={false}>
                    <div className="modal-dialog modal-lg" role="document" style={{marginTop: 0+'px',marginBottom: 0+'px'}}>
                        <div className="modal-content" style={{border:"white"}}>
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLongTitle">
                                    Konfirmasi PLN POSTPAID
                                </h5>
                            </div>
                            <div className="modal-body">
                                <form>
                                    <div className="form-group m-form__group">
                                        <span className="m--font-boldest">
                                            <b>Nomor Pelanggan:</b>
                                        </span>
                                        <p className="form-control-static">{this.state.no_pelanggan}</p>
                                    </div>
                                    <div className="form-group">
                                        <span className="m--font-boldest">
                                            Produk:
                                        </span>
                                        <p className="form-control-static">{tx_product_postpaid.length !== 0 ? this.state.jenis_postpaid + " " + tx_product_postpaid.product_name : this.state.jenis_postpaid}</p>
                                    </div>
                                    <div className="form-group">
                                        <span className="m--font-boldest">
                                            Total Bayar:
                                        </span>
                                        <p className="form-control-static">Rp. {this.state.tx_tagihan_pelanggan?this.state.tx_tagihan_pelanggan.total:''}</p>
                                    </div>
                                    <div className="" style={{cursor: 'pointer', textAlign: 'right', marginBottom: '7px'}} onClick={()=>{self.setState({show_info_pelanggan: !self.state.show_info_pelanggan})}}>
                                        <i className={this.state.show_info_pelanggan ? 'la la-minus' : 'la la-plus'} style={{fontSize: '15px', marginRight: '5px'}}></i>
                                        <span className="m--font-boldest">
                                            Informasi Pembeli
                                        </span>
                                        <span style={{backgroundColor:'#666', height: '8px', marginLeft: '5px'}}/>
                                    </div>
                                    <div className="m-form__section m-form__section--first" style={this.state.show_info_pelanggan ? {display: 'block'} : {display: 'none'}}>
                                        <div className="m-form__group row" style={{marginBottom: '10px'}}>
                                            <label className="col-lg-3" style={{margin: 'auto', fontWeight: 600, textAlign: 'left'}}>
                                                Nama:
                                            </label>
                                            <div className="col-lg-9">
                                                <input onChange={(e) => this.on_input_change(e)}
                                                    type="text"
                                                    className="form-control m-input"
                                                    name="nama_pelanggan"
                                                    value={this.state.nama_pelanggan}
                                                    placeholder=""/>
                                            </div>
                                        </div>
                                        <div className="m-form__group row" style={{}}>
                                            <label className="col-lg-3" style={{margin: 'auto', fontWeight: 600, textAlign: 'left'}}>
                                                Nomor HP:
                                            </label>
                                            <div className="col-lg-9">
                                                <input onChange={(e) => this.on_input_change(e)}
                                                    type="text"
                                                    className="form-control m-input"
                                                    name="nomor_pelanggan"
                                                    value={this.state.nomor_pelanggan}
                                                    placeholder=""/>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className="modal-footer">
                                <p className="m--font-boldest" style={{marginLeft: 10+'px',marginRight: 10+'px',marginTop: 10+'px',textAlign: 'center',fontWeight: 600,fontSize: 'larger'}}>
                                    Apakah Anda yakin untuk melanjutkan transaksi ini?
                                </p>
                                <button type="button" onClick={this.on_btn_confirm_cancel} className="btn btn-secondary" data-dismiss="modal">
                                    Batal
                                </button>
                                <button type="button" onClick={this.on_btn_confirm_ok} className={this.state.tx_flag_processing ? 'btn btn-primary m-loader m-loader--light m-loader--right' : 'btn btn-primary'}>
                                    OK
                                </button>
                            </div>
                        </div>
                    </div>
                </Modal>

                <Modal className="modal" open={this.state.tx_show_modal_prepaid} onClose={this.on_btn_confirm_cancel} center showCloseIcon={false} closeOnOverlayClick={false}>
                    <div className="modal-dialog modal-lg" role="document" style={{marginTop: 0+'px',marginBottom: 0+'px'}}>
                        <div className="modal-content" style={{border:"white"}}>
                            <div className="modal-header">
                                <h5 className="modal-title">
                                    Konfirmasi PLN PREPAID
                                </h5>
                            </div>
                            <div className="modal-body">
                                <form>
                                    <div className="form-group m-form__group">
                                        <span className="m--font-boldest">
                                            <b>Nomor Meter:</b>
                                        </span>
                                        <p className="form-control-static">{this.state.no_pelanggan_prepaid}</p>
                                    </div>
                                    <div className="form-group">
                                        <span className="m--font-boldest">
                                            Produk:
                                        </span>
                                        <p className="form-control-static">{tx_product_prepaid.length !== 0 ? tx_product_prepaid.product_id + " " + tx_product_prepaid.product_name : ''}</p>
                                    </div>
                                    <div className="form-group">
                                        <span className="m--font-boldest">
                                            Info:
                                        </span>
                                        <p className="form-control-static">{this.state.tx_tagihan_prepaid.info ? 
                                            this.state.tx_tagihan_prepaid.info.split('|').map(function(item, key) {
                                                return (
                                                    <span key={key}>
                                                        {item}
                                                        <br/>
                                                    </span>
                                                )
                                            }) : '-'}</p>
                                    </div>
                                    <div className="form-group row" style={{marginBottom: 0}}>
                                        <div className="col-lg-4">
                                            <span className="m--font-boldest">
                                                Harga:
                                            </span>
                                            <p className="form-control-static">
                                                {this.state.tx_tagihan_prepaid.length !== 0 ? this.state.tx_tagihan_prepaid.nominal !== undefined ? this.state.tx_tagihan_prepaid.nominal : '-' : '-'}
                                            </p>
                                        </div>
                                        <div className="col-lg-4">
                                            <span className="m--font-boldest">
                                                Biaya Admin:
                                            </span>
                                            <p className="form-control-static">
                                                {this.state.tx_tagihan_prepaid.length !== 0 ? this.state.tx_tagihan_prepaid.fee !== undefined ? this.state.tx_tagihan_prepaid.fee : '-' : '-'}
                                            </p>
                                        </div>
                                    </div>



                                    <div className="form-group row" style={{marginBottom: 0}}>

                                    {tx_product_prepaid.surchargeprepaid >= 0 ? <div className="col-lg-4">
                                            <span className="m--font-boldest">
                                                Biaya Surcharge:
                                            </span>
                                            <p className="form-control-static">
                                            {this.state.tx_tagihan_prepaid.length !== 0 ? this.state.tx_tagihan_prepaid.fee !== undefined ? this.state.tx_tagihan_prepaid.surcharge : '-' : '-'}

                                            </p>
                                        </div>: ''}



                                        <div className="col-lg-4">
                                        <span className="m--font-boldest">
                                            Total Bayar:
                                        </span>
                                        <div className="form-control-static">
                                            {this.state.tx_tagihan_prepaid.length !== 0 ? this.state.tx_tagihan_prepaid.total !== undefined ? this.state.tx_tagihan_prepaid.total : this.state.tx_tagihan_prepaid :
                                                <div className="m-loader m-loader--brand" 
                                                    style={{width: 30+'px', display: 'inline-block'}}>
                                                </div>}
                                        </div>
                                        </div>
                                    </div>
                                    <div className="" style={{cursor: 'pointer', textAlign: 'right', marginBottom: '7px'}} onClick={()=>{self.setState({show_info_pelanggan: !self.state.show_info_pelanggan})}}>
                                        <i className={this.state.show_info_pelanggan ? 'la la-minus' : 'la la-plus'} style={{fontSize: '15px', marginRight: '5px'}}></i>
                                        <span className="m--font-boldest">
                                            Informasi Pembeli
                                        </span>
                                        <span style={{backgroundColor:'#666', height: '8px', marginLeft: '5px'}}/>
                                    </div>
                                    <div className="m-form__section m-form__section--first" style={this.state.show_info_pelanggan ? {display: 'block'} : {display: 'none'}}>
                                        <div className="m-form__group row" style={{marginBottom: '10px'}}>
                                            <label className="col-lg-3" style={{margin: 'auto', fontWeight: 600, textAlign: 'left'}}>
                                                Nama:
                                            </label>
                                            <div className="col-lg-9">
                                                <input onChange={(e) => this.on_input_change(e)}
                                                    type="text"
                                                    className="form-control m-input"
                                                    name="nama_pelanggan"
                                                    value={this.state.nama_pelanggan}
                                                    placeholder=""/>
                                            </div>
                                        </div>
                                        <div className="m-form__group row" style={{}}>
                                            <label className="col-lg-3" style={{margin: 'auto', fontWeight: 600, textAlign: 'left'}}>
                                                Nomor HP:
                                            </label>
                                            <div className="col-lg-9">
                                                <input onChange={(e) => this.on_input_change(e)}
                                                    type="text"
                                                    className="form-control m-input"
                                                    name="nomor_pelanggan"
                                                    value={this.state.nomor_pelanggan}
                                                    placeholder=""/>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className="modal-footer">
                                <p className="m--font-boldest" style={{marginLeft: 10+'px',marginRight: 10+'px',marginTop: 10+'px',textAlign: 'center',fontWeight: 600,fontSize: 'larger'}}>
                                    Apakah Anda yakin untuk melanjutkan transaksi ini?
                                </p>
                                <button type="button" onClick={this.on_btn_confirm_cancel} className="btn btn-secondary" data-dismiss="modal">
                                    Batal
                                </button>
                                <button disabled={this.state.tx_button_ok ? false : true}  type="button" onClick={this.on_btn_confirm_ok_prepaid} className={this.state.tx_flag_processing ? 'btn btn-primary m-loader m-loader--light m-loader--right' : 'btn btn-primary'}>
                                    OK
                                </button>
                            </div>
                        </div>
                    </div>
                </Modal>
                {this.state.tx_show_struk ? <CetakStruk data={tx_result_success} on_close={()=>{
                    self.setState({tx_show_struk: false})
                }} /> : ''}
                {this.state.show_cetak_tagihan ? <CetakTagihan data={{product_id: this.state.tx_tagihan_pelanggan.product_id, product_name: this.state.tx_tagihan_pelanggan.product_name, biller_number: this.state.no_pelanggan, info:this.state.tx_tagihan_pelanggan.info, nominal: util.rupiah_format(this.state.tx_tagihan_pelanggan.nominal), fee: util.rupiah_format(this.state.tx_tagihan_pelanggan.fee), total: util.rupiah_format(this.state.tx_tagihan_pelanggan.total), on_cetak_close: ()=>{
                    self.setState({show_cetak_tagihan: false})
                }}}/> : ''}
            </div>
        </React.Fragment>
        )
    }
}

const mapDispatchToProps = (dispatch) => ({
    update_current_page:  val => dispatch(do_action(action.ACTION_UPDATE_CURRENT_PAGE, val)),
    update_balance: val => dispatch(do_action(action.ACTION_UPDATE_USER_BALANCE, val))
})

const mapStateToProps = (state) => ({
    session : state.Session,
    profile: state.Profile
})

export default connect(mapStateToProps, mapDispatchToProps)(AgentPLN)