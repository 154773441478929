import React, {Component} from 'react';
import {connect} from 'react-redux';
import Modal from 'react-responsive-modal';
import moment from 'moment';
import ReactToPrint from "react-to-print";
import util from '../../helper/util';

class CetakStruk extends Component {

    constructor(props) {
        super(props);
        this.cetak_btn_ref = React.createRef();
        util.log(this.props.data);
    }

    componentDidMount() {
        let self = this;
    }

    handleAfterPrint = () => {
        this.props.on_close()
    }

    handleBeforePrint = () => {
    }

    renderContent = () => {
        return this.componentRef;
    }

    renderTrigger = () => {
        return <button type="button" onClick={this.on_cetak} className='btn btn-primary'>Cetak</button>
    }

    setRef = (ref) => {
        this.componentRef = ref;
    }

    render() {
        let data = this.props.data;
        let item_style = {padding: '0 0'};
        let fontValue = 'courier12';
        let fontSizeValue = '14px';
        let spacing = 'normal';
        let spaceLineHeight = '17px';
        let fontWeightValue = 'normal';
        let table_cell_style = {marginBottom:0+'px',fontFamily:fontValue,fontSize:fontSizeValue,display:'table-cell', width:'20%',color:'black',letterSpacing: spacing, fontWeight: fontWeightValue};

        if (!Array.isArray(data)) {
            let items = [];
            items.push(data);
            data = items;
        }

        let agent_info = this.props.profile.name ? this.props.profile.name : ""
        if (agent_info.length !== 0) {
            if (this.props.data.workstation_code && this.props.data.workstation_code.length !== 0) {
                agent_info = agent_info + " (" + this.props.data.workstation_code + ")"
            }
        } else {
            if (this.props.data.workstation_code && this.props.data.workstation_code.length !== 0) {
                agent_info = this.props.data.workstation_code
            }
        }
        util.log('data', data);

        const ItemView = (props, key) =>
            <div className="row receipt-item">
                <div className="col-lg-12">
                    <form className="m-form m-form--fit m-form--label-align-right">
                        <div className="m-portlet__body">
                            <div className="row">
                                <h3 style={{marginLeft:'15px',marginBottom:'0', fontWeight: fontWeightValue}}>PosFin</h3>
                            </div>
                            <div className="row">
                                <div className="col-lg-12" style={{}}>
                                    <p style={{fontFamily:fontValue,fontSize: fontSizeValue,marginBottom:'6px',marginTop: '5px',    lineHeight:spaceLineHeight,color:'black',letterSpacing: spacing, fontWeight: fontWeightValue}}><span style={{fontWeight:600}}>Authorized by PT. POS INDONESIA (PERSERO)</span><br/>TGL {moment().format("DD-MM-YYYY hh:mm")}<br/>{agent_info}</p>
                                </div>
                            </div>
                            <div className="m-form__section m-form__section--first" style={{lineHeight: spaceLineHeight}}>
                                <div style={{display:'table',width:'100%',tableLayout:'fixed'}}>
                                    <div className="form-group m-form__group row" style={{padding: '5px 0', display:'table-caption'}}>
                                        <span style={{fontFamily:fontValue,fontSize:fontSizeValue,padding:'0 15px',display: 'table-cell',color:'black',letterSpacing: spacing, fontWeight: fontWeightValue}}>
                                            TXID {props.data ? (props.data.trx_code ? props.data.trx_code.toUpperCase() : '-') : ''}
                                        </span>
                                    </div>
                                    <div className="form-group m-form__group row" style={{padding: '5px 0', display:'table-row'}}>
                                        <span style={table_cell_style}>
                                            TXTanggal
                                        </span>
                                        <span style={{fontFamily:fontValue,fontSize:fontSizeValue,padding:'0 0px',display: 'table-cell',color:'black',letterSpacing: spacing, fontWeight: fontWeightValue}}>
                                            :&nbsp;{props.data ? props.data.date_time : '-'}
                                        </span>
                                    </div>
                                    <div className="form-group m-form__group row" style={{padding: '5px 0', display:'table-row'}}>
                                        <span style={table_cell_style}>
                                            Produk
                                        </span>
                                        <span style={{fontFamily:fontValue,fontSize:fontSizeValue,padding:'0 0px',display: 'table-cell',color:'black',letterSpacing: spacing, fontWeight: fontWeightValue}}>
                                            :&nbsp;{props.data ? props.data.product_id : ''}
                                            &nbsp;
                                            {props.data ? props.data.product_name : ''}
                                        </span>
                                    </div>
                                </div>

                                <div className="form-group m-form__group row" style={{padding: '0 0 3px 0'}}>
                                    <div style={{padding:'0px 15px'}}>
                                        <span style={{fontFamily:fontValue,fontSize:fontSizeValue,color:'black',letterSpacing: spacing, fontWeight: fontWeightValue,whiteSpace:'pre-wrap'}}>
                                            {props.data ? props.data.info.split('|').map(function(item, key) {
                                                    return (
                                                        item.length !== 0 ?
                                                        <span key={key} style={{}}>
                                                            {item.trim()}
                                                            <br/>
                                                        </span>
                                                        : ''
                                                    )
                                                }) : ''}
                                        </span>
                                    </div>
                                </div>

                                <div className="form-group m-form__group row" style={item_style}>
                                    <span style={{marginBottom:0+'px',fontFamily:fontValue,fontSize:fontSizeValue,flex: '0 0 33.33333%',maxWidth:'25%',paddingLeft: '15px',color:'black',letterSpacing: spacing, fontWeight: fontWeightValue}}>
                                        Harga Produk 
                                    </span>
                                    <div style={{}}>
                                        <span style={{fontSize:'15px',fontFamily: fontValue,color:'black',letterSpacing:spacing,fontWeight: 600,paddingRight:'30px',paddingLeft:'20px'}}>
                                            : {props.data && props.data.nominal ? (typeof props.data.nominal === 'number' ? "Rp. " + util.rupiah_format(props.data.nominal) : props.data.nominal) : '0'}
                                        </span>
                                    </div>
                                </div>

                                <div className="form-group m-form__group row" style={item_style}>
                                    <span style={{marginBottom:0+'px',fontFamily:fontValue,fontSize:fontSizeValue,flex: '0 0 33.33333%',maxWidth:'25%',paddingLeft: '15px',color:'black',letterSpacing: spacing, fontWeight: fontWeightValue}}>
                                        Biaya Admin
                                    </span>
                                    <div style={{}}>
                                        <span style={{fontSize:'15px',fontFamily: fontValue,color:'black',letterSpacing: spacing, fontWeight: 600,paddingRight:'30px',paddingLeft:'20px'}}>
                                            : {props.data && props.data.fee ? (typeof props.data.fee === 'number' ? "Rp. " + util.rupiah_format(props.data.fee) : props.data.fee) : '0'}
                                        </span>
                                    </div>
                                </div>

                                <div className="form-group m-form__group row" style={item_style}>
                                    <span style={{marginBottom:0+'px',fontFamily:fontValue,fontSize:fontSizeValue,flex: '0 0 33.33333%',maxWidth:'25%',paddingLeft: '15px',color:'black',letterSpacing: spacing, fontWeight: fontWeightValue}}>
                                        Biaya Surcharge
                                    </span>
                                    <div style={{}}>
                                        <span style={{fontSize:'15px',fontFamily: fontValue,color:'black',letterSpacing: spacing, fontWeight: 600,paddingRight:'30px',paddingLeft:'20px'}}>
                                            : {props.data && props.data.surcharge ? (typeof props.data.surcharge === 'number' ? "Rp. " + util.rupiah_format(props.data.surcharge) : props.data.surcharge) : '0'}
                                        </span>
                                    </div>
                                </div>

                                <div className="form-group m-form__group row" style={item_style}>
                                    <span style={{marginBottom:0+'px',fontFamily:fontValue,fontSize:fontSizeValue,flex:'0 0 33.33333%',maxWidth:'25%',paddingLeft: '15px',color:'black',letterSpacing: spacing, fontWeight: fontWeightValue}}>
                                        Total 
                                    </span>
                                    <div style={{}}>
                                        <span style={{fontSize:'15px',fontFamily: fontValue,color:'black',letterSpacing: spacing, fontWeight: 600,paddingRight:'30px',paddingLeft:'20px'}}>
                                            : {props.data && props.data.total ? (typeof props.data.total === 'number' ? "Rp. " + util.rupiah_format(props.data.total) : props.data.total) : '0'}
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <p style={{marginLeft: 0+'px',marginRight: 0+'px',marginTop: 5+'px',textAlign:'left',fontFamily:fontValue,fontSize:fontSizeValue,marginBottom: 5+'px',color:'black',lineHeight:spaceLineHeight,letterSpacing: spacing, fontWeight: fontWeightValue}}>Terima kasih atas kepercayaan Anda.<br/>Simpan struk ini sebagai bukti pembayaran yang SAH.</p>

                        </div>
                    </form>
                </div>
            </div>

        return (
            <Modal className="modal" open={true} onClose={this.props.on_close} center showCloseIcon={true} closeOnOverlayClick={false}>
                <div className='modal-dialog modal-lg' role='document' style={{marginTop:0+'px',marginBottom: 0+'px',width:'535px'}}>
                    <div className="modal-content" style={{border:'white',backgroundColor:'transparent'}}>
                        <div className="modal-header">
                            <h5 className="modal-title">
                                Cetak Struk
                            </h5>
                        </div>
                        <div className="modal-body" ref={this.setRef}>
                            {data.map(function(item, key) {
                                return (
                                    <ItemView data={item} key={key}/>
                                )
                            })}
                        </div>
                        <div className="modal-footer">
                            <ReactToPrint ref={this.cetak_btn_ref}
                                trigger={this.renderTrigger}
                                content={this.renderContent}
                                onBeforePrint={this.handleBeforePrint}
                                onAfterPrint={this.handleAfterPrint}
                                pageStyle="@page{size: auto A4 portrait;}"
                            />
                        </div>
                    </div>
                </div>
            </Modal>
        )
    }
}

const mapStateToProps = (state) => ({
    profile: state.Profile
})

export default connect(mapStateToProps, null)(CetakStruk)
