import React, { Component } from 'react';
import HeaderTopBar from './headerTopBar';
import PospayLogo from './pospay-logo.svg';

class Header extends Component {
    render() {
        return (
            <header className="m-grid__item  m-header" data-minimize-offset="200" data-minimize-mobile-offset="200">
                <div className="m-container m-container--fluid m-container--full-height">
                    <div className="m-stack m-stack--ver m-stack--desktop">
                        <div className="m-stack__item m-brand  m-brand--skin-dark" style={{paddingLeft: '15px',paddingRight:'15px'}}>
                            <div className="m-stack m-stack--ver m-stack--general">
                                <div className="m-stack__item m-stack__item--middle m-brand__logo">
                                    <div className="m-brand__logo-wrapper" style={{display:'inline-flex', marginTop: '10px'}}>
                                        <img src={PospayLogo} height="50" />
                                    </div>
                                </div>
                                <div className="m-stack__item m-stack__item--middle m-brand__tools">
                                    <a id="m_aside_left_minimize_toggle" className="m-brand__icon m-brand__toggler m-brand__toggler--left m--visible-desktop-inline-block">
                                        <span></span>
                                    </a>
                                    <a id="m_aside_left_offcanvas_toggle" className="m-brand__icon m-brand__toggler m-brand__toggler--left m--visible-tablet-and-mobile-inline-block">
                                        <span></span>
                                    </a>
                                    <a id="m_aside_header_menu_mobile_toggle" className="m-brand__icon m-brand__toggler m--visible-tablet-and-mobile-inline-block">
                                        <span></span>
                                    </a>
                                    <a id="m_aside_header_topbar_mobile_toggle" className="m-brand__icon m--visible-tablet-and-mobile-inline-block">
                                        <i className="flaticon-more"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <HeaderTopBar on_page_changed={this.props.on_page_changed} on_logout={this.props.on_logout}/>
                    </div>
                </div>
            </header>
        )
    }
}

export default Header