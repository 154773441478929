import { connect } from 'react-redux'
import React, { Component } from 'react'
import moment from 'moment'
import request from 'superagent'

import MetronicIndex from '../layout/metronic/index'
import { Form, FormGroup } from '../component/form/form'
import { Portlet, PortletHead, PortletBody, PortletFooter, PortletAlert, PortletStruk } from '../component/widget/portlet'

import apis from '../helper/api'
import localdata from '../helper/localdata'
import action from '../helper/action'
import do_action from '../action'
import config from '../helper/config'
import swal from 'sweetalert'
import Modal from 'react-responsive-modal'
import util from '../helper/util'
import CetakTagihan from '../component/form/cetak_inquiry'
import CetakStruk from '../component/form/cetak_struk'
import ReactGA from 'react-ga'

let tx_product = ""
let start_time_process_seconds = 0

class AgentDonasi extends Component {
    constructor(props) {
        super(props)
        this.state = {
            no_pelanggan: "",
            product_denom_prepaid: [],
            tx_product_denom_prepaid: "",
            denoms: [],
            tx_show_modal: false,
            tx_show_struk: false,
            tx_flag_processing: false,
            tx_tagihan_pelanggan: "",
            tx_result_message: "",
            tx_result_success: "",
            tx_product_sel: "",
            scroll_interval_id: 0,
            show_info_pelanggan: false, 
            nama_pelanggan: "", 
            alamat_pelanggan: "",
            show_btn_bayar: true,
            konsumen: false,
            mitra: false,
            tx_tagihan_surcharge: 0,
            tx_button_ok: false,
            konsumen: false,
            mitra: false,
            tx_show_surcharge:false,
            tx_show_btn_surcharge:false,
            tx_show_btn_beli:true,
            surcharge:""
        }
        this.on_btn_confirm_cancel = this.on_btn_confirm_cancel.bind(this)
        this.on_btn_confirm_ok = this.on_btn_confirm_ok.bind(this)
        this.on_btn_struk_cancel = this.on_btn_struk_cancel.bind(this)
        this.on_btn_struk_ok = this.on_btn_struk_ok.bind(this)
        this.on_btn_cetak_struk = this.on_btn_cetak_struk.bind(this)
        this.on_key_press = this.on_key_press.bind(this)        
        this.on_grouped_change = this.on_grouped_change.bind(this) 
        this.on_btn_surcharge = this.on_btn_surcharge.bind(this)
    }

    componentDidMount() {
        this.scroll_to_top()
        this.props.update_current_page('donasi')
        let self = this
        tx_product = ""
        localdata.get_product("SERVICE_DONATION").then((payload) => {
            util.log("SERVICE_DONATION: ", payload)
            if (payload.length !== 0) {
                self.setState({denoms : payload})
            }
        }).catch((err) => {
            util.log("error : ", err)
        })
    }

    scroll_step() {
        if (window.pageYOffset === 0) {
            clearInterval(this.state.scroll_interval_id);
        }
        window.scroll(0, window.pageYOffset - 50);
    }

    scroll_to_top() {
        let scroll_interval_id = setInterval(this.scroll_step.bind(this), 16.66);
        this.setState({scroll_interval_id: scroll_interval_id});
    }

    on_input_change(e) {
        this.setState({[e.target.name]: e.target.value})
    }

    
    
    on_btn_surcharge() {        
        let self = this
        if (self.state.no_pelanggan.length !== 0) {
            if (self.state.nama_pelanggan.length === 0) {
                swal("Ups!", "Nama donatur tidak boleh kosong", "warning")
                return
            }
            if (self.state.alamat_pelanggan.length === 0) {
                swal("Ups!", "Alamat donatur tidak boleh kosong", "warning")
                return
            }
            // let biller_number = this.state.no_pelanggan + "|" + tx_product.product_nominal + "|" + self.state.nama_pelanggan + "|" + self.state.alamat_pelanggan
        this.setState({tx_show_modal: false,tx_show_surcharge: true,tx_show_btn_beli:false});        
        }
    }

    on_grouped_change(e) {       
        try {
          if (e.target.name === 'konsumen') {
            this.satu = e.target.checked
            this.setState({konsumen: e.target.checked});
 
        }
        if (e.target.name === 'mitra') {
             this.setState({mitra: e.target.checked});
            this.dua = e.target.checked
        }
         this.status = (this.satu === true && this.dua === true) ? true : false;
         this.setState({tx_button_ok: this.status});
       
    } catch(err) {
        util.log("[on_grouped_change] error", err)
    }
    }
     



    on_key_press(e) {
        util.log(e)
        if (e.keyCode == 13) {
            if (e.target.name == "no_pelanggan") {
                this.on_btn_tagihan()
            }
        }
    }

    on_btn_cetak_struk(el) {
        util.log("on_btn_cetak_struk");
        if (this.state.tx_result_success.length !== 0) {
            util.log(this.state.tx_result_success.data);
            if (util.is_electron()) {
                const btn = el.target;
                btn.classList.add("m-loader", "m-loader--primary", "m-loader--right");
                setTimeout(function() {
                    btn.classList.remove("m-loader", "m-loader--primary", "m-loader--right");
                }, 3000);
                const agent_info = this.props.profile;
                util.electron_print_receipt(agent_info, this.state.tx_result_success.data);
            } else {
                this.setState({tx_show_struk: true});
            }
        }
    }

    on_btn_struk_ok() {
        this.setState({tx_show_struk: false});
    }

    on_btn_struk_cancel() {
        this.setState({tx_show_struk: false})
    }

    on_select_change(e) {
        let self = this
        if (e.target.value.length !== 0) {
            let val = e.target.value
            localdata.get_denom_by_product_id("SERVICE_DONATION", val).then((payload) => {
                util.log("SERVICE_DONATION [" + val + "] : ", payload)
                if (payload.length !== 0) {
                    self.setState({product_denom_prepaid : payload})
                }
            }).catch((err) => {
                util.log("error : ", err)
            })
        } else {
            self.setState({product_denom_prepaid : []})
        }
        this.setState({tx_product_sel: e.target.value})
    }

    on_btn_tagihan() {
        if (this.state.tx_flag_inquiry_processing) return
        let self = this
        if (self.state.no_pelanggan.length !== 0) {
            if (self.state.nama_pelanggan.length === 0) {
                swal("Ups!", "Nama donatur tidak boleh kosong", "warning")
                return
            }
            if (self.state.alamat_pelanggan.length === 0) {
                swal("Ups!", "Alamat donatur tidak boleh kosong", "warning")
                return
            }
            let biller_number = this.state.no_pelanggan + "|" + tx_product.product_nominal + "|" + self.state.nama_pelanggan + "|" + self.state.alamat_pelanggan
            let body = {
                "session_id" : self.props.session,
                "product_id" : tx_product.product_id,
                "product_code" : tx_product.product_denom,
                "biller_number": biller_number,
                "surcharge":this.state.surcharge

            }
            self.setState({tx_flag_inquiry_processing: true})
            start_time_process_seconds =  Math.floor(Date.now() / 1000)
            request.post(apis.inquiry)
                .set("Content-Type", "application/json")
                .timeout(120*1000)
                .send(body)
                .end(function(err, res) {
                    let message_error = ""
                    if (err || !res.ok) {
                        util.log(err)
                        message_error = {"body": err.message, "title": "Transaksi Gagal"}
                        if (res !== undefined) {
                            message_error.body = "Error code " + res.statusCode
                        }
                    } else {
                        util.log(res);
                        if (res.statusCode == 200) {
                            let payload = res.body
                            util.log(payload)
                            if (payload.status == "OK") {
                                self.setState({tx_tagihan_pelanggan: payload.results, show_btn_bayar: true})
                                self.scroll_to_top()
                                if (start_time_process_seconds !== 0) {
                                    let end_time_process = Math.floor(Date.now() / 1000)
                                    let action = config.ga.event.inquiry + '-' + util.time_range_ga(end_time_process-start_time_process_seconds)
                                    ReactGA.event({
                                        category: 'transaction',
                                        action: action,
                                        label: payload.results.product_name
                                    })
                                }
                            } else {
                                util.on_error(payload.error)
                                message_error = {"body": res.body.error.message, "title": "Transaksi Gagal"}
                                if (res.body.display_message !== undefined) {
                                    message_error.title = res.body.error.message
                                    message_error.body = res.body.display_message
                                }
                            }
                        } else {
                            message_error = {"body": "HTTP error " + res.statusCode, "title": "Transaksi Gagal"}
                        }
                    }
                    if (message_error.length != 0) {
                        swal(message_error.title, message_error.body, "error")
                    }
                    self.setState({tx_flag_inquiry_processing: false})
                    start_time_process_seconds = 0
                });
        }
    }

    on_btn_reset() {
        this.setState({no_pelanggan: "", tx_result_message: "", tx_result_success: "", tx_tagihan_pelanggan: "", product_denom_prepaid: [], tx_product_sel: "", tx_product_denom_prepaid: ""})
        tx_product = ""
        this.setState({tx_show_modal: false,tx_show_surcharge:false,surcharge:0,tx_show_btn_beli:true})

    }

    on_btn_confirm_ok() {
        if (this.state.tx_flag_processing) return
        if (tx_product !== "") {
            let self = this
            let whitelabel_36 = config.whitelabel.toString(36).toUpperCase()
            let user_id = this.props.profile.userid.toString(36).toUpperCase()
            let prefix = whitelabel_36.substring(whitelabel_36.length - 3)
            let suffix = user_id.substring(user_id.length - 3)
            let token = util.gen_token(prefix, this.props.session, suffix)
            let biller_number = this.state.no_pelanggan
            biller_number = biller_number + "|" +  tx_product.product_nominal
            if (this.state.nama_pelanggan !== undefined && this.state.nama_pelanggan.length !== 0) {
                biller_number = biller_number + "|" +  this.state.nama_pelanggan
            }
            if (this.state.alamat_pelanggan !== undefined && this.state.alamat_pelanggan.length !== 0) {
                biller_number = biller_number + "|" +  this.state.alamat_pelanggan
            }

            let body = {
                "session_id" : this.props.session,
                "product_id" : tx_product.product_id,
                "product_code" : tx_product.product_denom,
                "biller_number" : biller_number,
                "sign_token" : token,
                "surcharge":this.state.surcharge

            }
            
            self.setState({tx_flag_processing: true})
            start_time_process_seconds =  Math.floor(Date.now() / 1000)
            request.post(apis.purchase)
                .set("Content-Type", "application/json")
                .timeout(120*1000)
                .send(body)
                .end(function(err, res) {
                    let message_result = ""
                    let success_result = ""
                    if (err || !res.ok) {
                        util.log(err)
                        message_result = {title: "Error", body: err.message}
                        if (res != undefined) {
                            message_result.body = "Error code " + res.statusCode
                        }
                        if (err.code === 504) {
                            message_result.body = config.timeout_message
                        }
                    } else {
                        util.log(res);
                        if (res.statusCode == 200) {
                            let payload = res.body
                            util.log(payload)
                            if (payload.status == "OK") {
                                let results = payload.results
                                let success_info = "Biller No: " + results.biller_number + "|Produk: " + results.product_id + " " + results.product_name + "|Harga Produk: Rp. " + util.rupiah_format(results.nominal) + "|Biaya Admin: Rp. " + util.rupiah_format(results.fee) + "|Biaya Surcharge: Rp. " + util.rupiah_format(results.surcharge) +  "|Total Bayar: Rp. " + util.rupiah_format(results.total) 
                                success_result = {title: "Transaksi Sukses", body: success_info, data: results}
                                self.props.update_balance(results.balance_after)
                                swal(success_result.title, payload.display_message, "success")
                                self.setState({show_btn_bayar:false})
                                if (start_time_process_seconds !== 0) {
                                    let end_time_process = Math.floor(Date.now() / 1000)
                                    let action = config.ga.event.payment + '-' + util.time_range_ga(end_time_process-start_time_process_seconds)
                                    ReactGA.event({
                                        category: 'transaction',
                                        action: action,
                                        label: results.product_name
                                    })
                                }
                            } else {
                                util.on_error(payload.error)
                                message_result = {title: "Error", body: res.body.error.message}
                                if (res.body.display_message !== undefined) {
                                    message_result.body = message_result.body + ". " + res.body.display_message
                                }
                            }
                        } else {
                            message_result = {title: "Error", body: res.statusCode}
                        }
                    }
                    if (message_result.length != 0) {
                        swal(message_result.title, message_result.body, "error")
                        message_result.title = "[" + moment().format("DD-MM-YYYY hh:mm") + "] " + message_result.title
                    }
                    self.setState({tx_flag_processing: false, tx_result_message: message_result, tx_result_success: success_result, tx_show_modal: false})
                    start_time_process_seconds = 0
                });
        }
    }

    on_btn_confirm_cancel() {
        this.setState({tx_tagihan_pelanggan: ''})
    }

    on_btn_cetak_tagihan() {
        this.setState({show_cetak_tagihan: true})
    }

    on_denom_clicked(e) {
        let target = e.currentTarget
        tx_product = {
            "product_id" : target.dataset.product_id,
            "product_denom" : target.dataset.product,
            "product_name" : target.dataset.name,
            "product_nominal" : target.dataset.denom,
            "max_surcharge" : target.dataset.max_surcharge

        }
        util.log("tx_product", tx_product)
        this.setState({tx_product_denom_prepaid: target.dataset.product})
        this.nomor_pelanggan_input.focus();
        if(target.dataset.max_surcharge > 0 )
            {
                this.setState({tx_show_btn_surcharge: true});
            }
            else
            {
                this.setState({tx_show_btn_surcharge: false,tx_show_btn_beli:true,tx_show_surcharge:false});
            }      
    }

    denoms_view() {
        const item_per_row = 3;
        const rows = [];
        let self = this;
        let product_denom = self.state.product_denom_prepaid;
        const items = product_denom.denoms.map((denom, i) => {
                    return (<a data-denom={denom.denom} data-product={denom.product_code} data-name={denom.product_name} data-product_id={product_denom.product_id} data-max_surcharge={denom.max_surcharge} key={i} 
                            id={"product-item-" + i}
                            className="m-nav-grid__item m-nav-grid__item product-item" 
                            onClick={self.on_denom_clicked.bind(this)} 
                            style={this.state.tx_product_denom_prepaid ===  denom.product_code ? {backgroundColor : 'cadetblue',cursor:'pointer'} : {cursor:'pointer'}}>
                            <i className="m-nav-grid__icon flaticon-folder"></i>
                            <span className="m-nav-grid__text" style={this.state.tx_product_denom ===  denom.product_code ? {color:'white',fontWeight:600} : {color:'#575962',fontWeight:600}}>
                                {denom.product_name}
                            </span>
                        </a>
                    )
                })
        for(let row = 0; row < Math.ceil(product_denom.denoms.length / item_per_row); row += 1) {
            rows.push(<div key={ `row-${row}` } className="m-nav-grid__row">{items.slice(row*item_per_row, (row*item_per_row) + item_per_row)}</div>);
        }
        return rows;
    }

    render() {
        let self = this
        let tx_result_success = this.state.tx_result_success.data
        const padding_field = {paddingTop: 6+'px',paddingBottom: 6+'px'}
        const label_tagihan_style = {textAlign:'left',padding:0,marginTop:'auto',marginBottom:'auto',fontWeight:600}
        const value_tagihan_style = {fontWeight:600,width:'100%',textAlign:'right',padding:0}

        const DenomView = (props) => 
            <div style={{background: '#fff',border: '4px solid #f7f7fa',marginBottom: '18px'}} data-code-preview="true" data-code-html="true" data-code-js="false">
                <div className="m-demo__preview">
                    <div className="m-nav-grid">
                        {self.denoms_view()}
                    </div>
                </div>
            </div>


const SurchargeView = (props) => 
    <div className="col-lg-6">
        <div className="m-portlet m-portlet--success m-portlet--head-solid-bg m-portlet--rounded" style={{minHeight:'500px',background:'#fff'}}>
            <div className="m-portlet__head">
                <div className="m-portlet__head-caption">
                    <div className="m-portlet__head-title">
                        <span className="m-portlet__head-icon">
                            <i className="flaticon-interface-5"></i>
                        </span>
                        <h3 className="m-portlet__head-text">
                            Input Surcharge 
                        </h3>
                    </div>
                </div>
            </div>
            <div className="m-portlet__body">
                <div className="row">
                    <div className="col-lg-12">
                        <form>
                            <div className="form-group m-form__group">
                                <span className="m--font-boldest">
                                    No Pelanggan:
                                </span>
                                <p className="form-control-static">{this.state.no_pelanggan}</p>
                            </div>
                            <div className="form-group">
                                <span className="m--font-boldest">
                                    Produk ID:
                                </span>
                                <p className="form-control-static">{tx_product.product_id}</p>
                            </div>
                            <div className="form-group">
                                <span className="m--font-boldest">
                                    Produk:
                                </span>
                                <p className="form-control-static">{tx_product.product_name}</p>
                            </div>
                             <div className="form-group">
                            <span className="m--font-boldest">Input Surcharge: </span>
                            <input
                                    onChange={(e) => this.on_input_change(e)}
                                    type="text"
                                    className="form-control m-input"
                                    name="surcharge"
                                    value={this.state.surcharge}
                                    placeholder="Input Surcharge"/> 
                            <span class="text-danger small bold">Biaya Surcharge untuk produk ini maksimal Rp.{tx_product.max_surcharge} <br/> Biaya Surcharge ini belum termasuk PPH 2%<br/>Biaya Surcharge belum Termasuk PPN 11%</span>
                            <div className="topping">
                                <label className="m-checkbox m-checkbox--focus" style={{fontWeight: 500,display:'inline-grid',marginLeft:'15px',paddingLeft:'23px'}}>
                                <input type="checkbox" name="konsumen" id="1" checked={this.state.konsumen} onChange={this.on_grouped_change} />
                                Konsumen mengetahui dan setuju untuk biaya Surcharge
                                <span style={{height:'13px',width:'13px'}}></span>
                                </label>
                                <label className="m-checkbox m-checkbox--focus" style={{fontWeight: 500,display:'inline-grid',marginLeft:'15px',paddingLeft:'23px'}}>                                            
                                <input type="checkbox" name="mitra" id="2" checked={this.state.mitra} onChange={this.on_grouped_change} />
                                Mitra Agen bertanggung jawab atas biaya surcharge pada produk ini                                            <span style={{height:'13px',width:'13px'}}></span>
                                </label>  
                            </div>                                    
                            </div>
                            <div className=" m-portlet__foot m-portlet__foot--fit" style={{marginTop:'20px'}}>
                                <div className="m-form__actions">
                                    <div className="row">
                                        <p className="m--font-boldest" style={{marginLeft: 'auto',marginRight: 'auto',marginTop: '10px',textAlign: 'center',fontWeight: 600,fontSize: 'larger'}}>
                                            Apakah Anda yakin untuk melanjutkan transaksi ini?
                                        </p>
                                        <div style={{margin:'auto'}}>
                                            <button type="button" onClick={this.on_btn_confirm_cancel} className="btn btn-secondary" data-dismiss="modal">
                                                Cancel
                                            </button>
                                            &nbsp;&nbsp;

                                            <button disabled={this.state.tx_button_ok ? false : true}  onClick={() => this.on_btn_tagihan()} type="button" className={this.state.tx_flag_inquiry_processing ? 'btn btn-success m-loader m-loader--light m-loader--right' : 'btn btn-success'}>
                                                Cek Bayar
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>

        const TagihanView = (props) => 
        <div className="col-lg-6">
            <div className="m-portlet m-portlet--success m-portlet--head-solid-bg m-portlet--rounded" style={{minHeight:'500px',background:'#fff'}}>
                <div className="m-portlet__head">
                    <div className="m-portlet__head-caption">
                        <div className="m-portlet__head-title">
                            <span className="m-portlet__head-icon">
                                <i className="flaticon-interface-5"></i>
                            </span>
                            <h3 className="m-portlet__head-text">
                                Donasi Donatur {this.state.no_pelanggan}
                            </h3>
                        </div>
                    </div>
                </div>
                <div className="m-portlet__body" style={{paddingTop: 10+'px'}}>
                    <div className="row">
                        <div className="col-lg-12">
                            <form className="m-form m-form--fit m-form--label-align-right m-form--group-seperator-dashed">
                                <div className="form-group m-form__group row" style={padding_field}>
                                    <label className="col-lg-2 col-form-label" style={label_tagihan_style}>
                                        Produk :
                                    </label>
                                    <div className="col-lg-10" style={{paddingLeft:0,paddingRight:0}}>
                                        <label className="col-form-label" style={{padding:0}}>
                                            {tx_product.length !== 0 ? tx_product.product_name : ''}
                                        </label>
                                    </div>
                                </div>
                                <div className="form-group m-form__group row" style={padding_field}>
                                    <label className="col-lg-2 col-form-label" style={label_tagihan_style}>
                                        Info :
                                    </label>
                                    <div className="col-lg-10" style={{paddingLeft:0,paddingRight:0}}>
                                        <label className="col-form-label" style={{lineHeight:'20px',padding:0}}>
                                            {this.state.tx_tagihan_pelanggan.info.split('|').map(function(item, key) {
                                                return (
                                                    <span key={key}>
                                                        {item}
                                                        <br/>
                                                    </span>
                                                )
                                            })}
                                        </label>
                                    </div>
                                </div>
                                <div className="form-group m-form__group row" style={padding_field}>
                                    <label className="col-lg-4 col-form-label" style={label_tagihan_style}>
                                        Tagihan :
                                    </label>
                                    <div className="col-lg-8">
                                        <label className="col-form-label" style={value_tagihan_style}>
                                            Rp. {this.state.tx_tagihan_pelanggan?util.rupiah_format(this.state.tx_tagihan_pelanggan.nominal):''}
                                        </label>
                                    </div>
                                </div>
                                <div className="form-group m-form__group row" style={padding_field}>
                                    <label className="col-lg-4 col-form-label" style={label_tagihan_style}>
                                        Biaya Admin :
                                    </label>
                                    <div className="col-lg-8">
                                        <label className="col-form-label" style={value_tagihan_style}>
                                            Rp. {this.state.tx_tagihan_pelanggan?util.rupiah_format(this.state.tx_tagihan_pelanggan.fee):'0'}
                                        </label>
                                    </div>
                                </div>
                                <div className="form-group m-form__group row" style={padding_field}>
                                    <label className="col-lg-4 col-form-label" style={label_tagihan_style}>
                                        Biaya Surcharge :
                                    </label>
                                    <div className="col-lg-8">
                                        <label className="col-form-label" style={value_tagihan_style}>
                                            Rp. {this.state.tx_tagihan_pelanggan?util.rupiah_format(this.state.tx_tagihan_pelanggan.surcharge):'0'}
                                        </label>
                                    </div>
                                </div>


                                <div className="form-group m-form__group row" style={{paddingTop: 6+'px',paddingBottom: 6+'px',borderBottom: 0}}>
                                    <label className="col-lg-4 col-form-label" style={label_tagihan_style}>
                                        Total Bayar :
                                    </label>
                                    <div className="col-lg-8">
                                        <label className="col-form-label" style={value_tagihan_style}>
                                            Rp. {this.state.tx_tagihan_pelanggan?util.rupiah_format(this.state.tx_tagihan_pelanggan.total):''}
                                        </label>
                                    </div>
                                </div>
                                
                                <div className=" m-portlet__foot m-portlet__foot--fit" style={{marginTop:'20px'}}>
                                    <div className="m-form__actions">
                                        <div className="row">
                                            <p className="m--font-boldest" style={{marginLeft: 'auto',marginRight: 'auto',marginTop: '10px',textAlign: 'center',fontWeight: 600,fontSize: 'larger'}}>
                                                Apakah Anda yakin untuk melanjutkan transaksi ini?
                                            </p>
                                            <div style={{margin:'auto'}}>
                                                <button type="button" onClick={this.on_btn_confirm_cancel} className="btn btn-secondary" data-dismiss="modal">
                                                    Cancel
                                                </button>
                                                &nbsp;&nbsp;
                                                <button disabled={this.state.tx_button_ok ? false : true} type="button" onClick={this.on_btn_confirm_ok} className={this.state.tx_flag_processing ? 'btn btn-primary m-loader m-loader--light m-loader--right' : 'btn btn-primary'}>
                                                    Bayar
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        return (
        <React.Fragment>
            <div className="row">
                <div className="col-md-12">
                    {this.state.tx_result_message.length != 0 ? <PortletAlert icon="warning" title={this.state.tx_result_message.title} message={this.state.tx_result_message.body} /> : ""}
                    {this.state.tx_result_success.length != 0 ? <PortletStruk icon="warning" title={this.state.tx_result_success.title} message={this.state.tx_result_success.body} on_btn_cetak_struk={this.on_btn_cetak_struk}/> : ""}
                </div>
            </div>
            <div className="row">
                <div className="col-lg-6">
                    <div className="m-portlet m-portlet--tab" style={{}}>
                        <PortletHead headTxt={"Bayar Produk Donasi"} />
                        <Form>
                            <PortletBody>
                                <FormGroup>
                                    <label>Produk</label>
                                        <select className="form-control m-input" id="product-sel" value={this.state.tx_product_sel} onChange={(e) => this.on_select_change(e)}>
                                        <option key={999} value=""> -- Pilih Donasi -- </option>
                                        {
                                            self.state.denoms.length != 0 ? self.state.denoms.map((denom, i) =>
                                                <option key={i} value={denom.product_id} data-product_name={denom.product_name} data-max_surcharge={denom.max_surcharge}>
                                                    {denom.product_name}
                                                </option>
                                            ) : ''
                                        }
                                    </select>
                                </FormGroup>
                                {this.state.product_denom_prepaid.length !== 0 ? <FormGroup><DenomView/></FormGroup> : ""}
                                <FormGroup>
                                    <label>Nomor Telepon Donatur</label>
                                    <input 
                                        ref={input => {this.nomor_pelanggan_input = input}}
                                        onChange={(e) => this.on_input_change(e)}
                                        onKeyDown={this.on_key_press}
                                        type="text"
                                        className="form-control m-input"
                                        name="no_pelanggan"
                                        value={this.state.no_pelanggan}
                                        placeholder=""/>
                                </FormGroup>
                                <FormGroup>
                                    <label>Nama Donatur</label>
                                    <input onChange={(e) => this.on_input_change(e)}
                                        type="text"
                                        className="form-control m-input"
                                        name="nama_pelanggan"
                                        value={this.state.nama_pelanggan}
                                        placeholder=""/>
                                </FormGroup>
                                <FormGroup>
                                    <label>Alamat Donatur</label>
                                    <input onChange={(e) => this.on_input_change(e)}
                                        type="text"
                                        className="form-control m-input"
                                        name="alamat_pelanggan"
                                        value={this.state.alamat_pelanggan}
                                        placeholder=""/>
                                </FormGroup>
                            </PortletBody>
                            
                            <div className="row" style={{paddingLeft:'30px',paddingRight:'30px',paddingBottom:'30px'}}>
                                <div className="col-12">
                                {this.state.tx_show_btn_surcharge ? <button onClick={() => this.on_btn_surcharge()} type="button" className="btn btn-primary mr-2">
                                                    Input Surcharge
                                                </button> : ''}
                                                {this.state.tx_show_btn_beli ? 
                                    <button onClick={() => this.on_btn_tagihan()} type="button" className={this.state.tx_flag_inquiry_processing ? 'btn btn-success m-loader m-loader--light m-loader--right' : 'btn btn-success'}>
                                        Cek Bayar
                                    </button> : '' }
                                    &nbsp;&nbsp;
                                    <button onClick={() => this.on_btn_reset()} type="reset" className="btn btn-secondary">
                                        Reset
                                    </button>
                                </div>
                            </div>

                        </Form>
                    </div>
                </div>
                
                {this.state.tx_tagihan_pelanggan ? <TagihanView/> : ''}
                {this.state.tx_show_surcharge ? <SurchargeView/> : ''}

                {this.state.tx_show_struk ? <CetakStruk data={tx_result_success} on_close={()=>{
                    self.setState({tx_show_struk: false})
                }} /> : ''}

                {this.state.show_cetak_tagihan ? <CetakTagihan data={{product_id: this.state.tx_tagihan_pelanggan.product_id, product_name: this.state.tx_tagihan_pelanggan.product_name, biller_number: this.state.no_pelanggan, info:this.state.tx_tagihan_pelanggan.info, nominal: util.rupiah_format(this.state.tx_tagihan_pelanggan.nominal), fee: util.rupiah_format(this.state.tx_tagihan_pelanggan.fee), surcharge: util.rupiah_format(this.state.tx_tagihan_pelanggan.surcharge), total: util.rupiah_format(this.state.tx_tagihan_pelanggan.total), on_cetak_close: ()=>{
                    self.setState({show_cetak_tagihan: false})
                }}}/> : ''}

            </div>
        </React.Fragment>
        )
    }
}

const mapDispatchToProps = (dispatch) => ({
    update_current_page:  val => dispatch(do_action(action.ACTION_UPDATE_CURRENT_PAGE, val)),
    update_balance: val => dispatch(do_action(action.ACTION_UPDATE_USER_BALANCE, val))
})

const mapStateToProps = (state) => ({
    session : state.Session,
    profile: state.Profile
})

export default connect(mapStateToProps, mapDispatchToProps)(AgentDonasi)