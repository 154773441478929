import { connect } from 'react-redux'
import React, { Component } from 'react'
import request from 'superagent'
import moment from 'moment'
import { Form, FormGroup } from '../component/form/form'
import { Portlet, PortletHead, PortletBody, PortletFooter, PortletAlert, PortletTab, PortletTabHeader, PortletTabHeaderList, PortletTabBody, PortletTabBodyList, PortletStruk } from '../component/widget/portlet'
import apis from '../helper/api'
import localdata from '../helper/localdata'
import action from '../helper/action'
import do_action from '../action'
import config from '../helper/config'
import swal from 'sweetalert'
import Modal from 'react-responsive-modal'
import util from '../helper/util'
import CetakTagihan from '../component/form/cetak_inquiry'
import CetakStruk from '../component/form/cetak_struk'
import ReactGA from 'react-ga'

let tx_product_postpaid = ''
let tx_product_prepaid = ''
let start_time_process_seconds = 0
let iconnet = 786145

class AgentTV extends Component {
  constructor(props) {
    super(props)
    // tx_show_modal_postpaid: false,
    // tx_show_modal_prepaid: false,
    this.state = {
      no_pelanggan_postpaid: '',
      no_pelanggan_prepaid: '',
      product_postpaid: [],
      product_prepaid: [],
      product_denom_prepaid: [],
      tx_product_denom_prepaid: '',
      tx_product_prepaid: '',
      tx_product_postpaid: '',
      tx_tagihan_prepaid: '',
      tx_tagihan_pelanggan: '',
      tx_show_struk: false,
      tx_flag_processing: false,
      tx_flag_inquiry_processing: false,
      tx_result_message: '',
      tx_result_success: '',
      show_info_pelanggan: false,
      nama_pelanggan: '',
      nomor_pelanggan: '',
      
      tx_show_surcharge:false,
      tx_show_btn_surcharge:false,
      tx_show_btn_beli:true,
      surcharge:"",
      tx_button_bayar:true,
      
      tx_tagihan_surcharge: 0,
      tx_button_ok: false,
      konsumen: false,
      mitra: false,
      tx_show_surcharge_postpaid:false,
      tx_show_surcharge_prepaid:false,
      tx_show_btn_surcharge_postpaid:false,
      tx_show_btn_surcharge_prepaid:false,
       tx_show_btn_submit:true,
       surchargeprepaid:"",            
      tx_flag_processing_surchargepostpaid: false,
      tx_flag_processing_surchargeprepaid: false,
    }
    this.on_btn_confirm_cancel = this.on_btn_confirm_cancel.bind(this)
    this.on_btn_confirm_ok_postpaid = this.on_btn_confirm_ok_postpaid.bind(this)
    this.on_btn_confirm_ok_prepaid = this.on_btn_confirm_ok_prepaid.bind(this)
    this.on_btn_struk_cancel = this.on_btn_struk_cancel.bind(this)
    this.on_btn_struk_ok = this.on_btn_struk_ok.bind(this)
    this.on_btn_cetak_struk = this.on_btn_cetak_struk.bind(this)
    this.total_bayar_prepaid = this.total_bayar_prepaid.bind(this)
    this.on_key_press = this.on_key_press.bind(this)
    this.on_grouped_change = this.on_grouped_change.bind(this) 
    this.on_btn_surcharge = this.on_btn_surcharge.bind(this)
    this.on_btn_bayar_prepaid = this.on_btn_bayar_prepaid.bind(this)    
    this.on_btn_surcharge_prepaid = this.on_btn_surcharge_prepaid.bind(this) 
    // this.on_btn_surcharge = this.on_btn_surcharge.bind(this) 


    // this.on_btn_submit_nosurcharge = this.on_btn_submit_nosurcharge.bind(this)
  }

  componentDidMount() {
    let self = this
    this.props.update_current_page('tv')
    localdata
      .get_product('SERVICE_PAYTV_POSTPAID')
      .then((payload) => {
        util.log('SERVICE_PAYTV_POSTPAID: ', payload)
        if (payload.length !== 0) {
          self.setState({ product_postpaid: payload })
        }
      })
      .catch((err) => {
        util.log('error : ', err)
      })
    localdata
      .get_product('SERVICE_PAYTV_PREPAID')
      .then((payload) => {
        util.log('SERVICE_PAYTV_PREPAID: ', payload)
        if (payload.length !== 0) {
          self.setState({ product_prepaid: payload })
        }
      })
      .catch((err) => {
        util.log('error : ', err)
      })
  }

  on_input_change(e) {
    if (e.target.name === 'no_pelanggan_postpaid') {
      this.setState({ no_pelanggan_postpaid: e.target.value })
    } else if (e.target.name === 'no_pelanggan_prepaid') {
      this.setState({ no_pelanggan_prepaid: e.target.value })
    } else {
      this.setState({ [e.target.name]: e.target.value })
    }
  }


  
  on_grouped_change(e) {       
    try {
      if (e.target.name === 'konsumen') {
        this.satu = e.target.checked
        this.setState({konsumen: e.target.checked});

    }
    if (e.target.name === 'mitra') {
         this.setState({mitra: e.target.checked});
        this.dua = e.target.checked
    }
     this.status = (this.satu === true && this.dua === true) ? true : false;
     this.setState({tx_button_ok: this.status});
   
} catch(err) {
    util.log("[on_grouped_change] error", err)
}
}
 
  on_key_press(e) {
    util.log(e)
    if (e.keyCode === 13) {
      if (e.target.name === 'no_pelanggan_postpaid') {
        this.on_btn_tagihan()
      } else if (e.target.name === 'no_pelanggan_prepaid') {
        this.on_btn_bayar_prepaid()
      }
    }
  }

  on_select_change_postpaid(e) {
    if (e.target.value.length !== 0) {
      let product_name = e.target[e.target.selectedIndex].getAttribute('data-product_name')
      let max_surcharge = e.target[e.target.selectedIndex].getAttribute('data-max_surcharge')
      tx_product_postpaid = {
        product_id: e.target.value,
        product_name: product_name,
        max_surcharge: max_surcharge,
      }
      util.log('max_surcharge: ', max_surcharge)
      if(max_surcharge > 0 )
        {
            this.setState({tx_show_btn_surcharge_postpaid: true});
        }
        else
        {
            this.setState({tx_show_btn_surcharge_postpaid: false,tx_show_btn_beli:true,tx_show_surcharge_postpaid:false});
        }   
    } else {
      tx_product_postpaid = ''
    }
    util.log('on_select_change: ', e.target.value)
    this.setState({ tx_product_postpaid: e.target.value })
    this.nomor_pelanggan_postpaid_input.focus()
  }

  on_select_change_prepaid(e) {
    let self = this
    if (e.target.value.length !== 0) {
      let val = e.target.value
      localdata
        .get_denom_by_product_id('SERVICE_PAYTV_PREPAID', val)
        .then((payload) => {
          util.log('SERVICE_PAYTV_PREPAID [' + val + '] : ', payload)
          if (payload.length !== 0) {
            self.setState({ product_denom_prepaid: payload })
          }
        })
        .catch((err) => {
          util.log('error : ', err)
        })
    } else {
      self.setState({ product_denom_prepaid: [] })
    }
    this.setState({ tx_product_prepaid: e.target.value })
  }

  on_btn_cetak_struk(el) {
    util.log('on_btn_cetak_struk')
    if (this.state.tx_result_success.length !== 0) {
      util.log(this.state.tx_result_success.data)
      if (util.is_electron()) {
        const btn = el.target
        btn.classList.add('m-loader', 'm-loader--primary', 'm-loader--right')
        setTimeout(function () {
          btn.classList.remove('m-loader', 'm-loader--primary', 'm-loader--right')
        }, 3000)
        const agent_info = this.props.profile
        util.electron_print_receipt(agent_info, this.state.tx_result_success.data)
      } else {
        this.setState({ tx_show_struk: true })
      }
    }
  }

  on_btn_struk_ok() {
    this.setState({ tx_show_struk: false })
  }

  on_btn_struk_cancel() {
    this.setState({ tx_show_struk: false })
  }

  on_btn_confirm_cancel() {
    this.setState({ tx_tagihan_pelanggan: '', tx_tagihan_prepaid: '' })
    this.setState({tx_show_modal: false,tx_show_surcharge:false,surcharge:0,tx_show_btn_beli:true})

  }

  on_btn_tagihan() {
    if (this.state.tx_flag_inquiry_processing) return
    let self = this
    if (self.state.no_pelanggan_postpaid.length !== 0) {
      if (self.state.tx_product_postpaid.length === 0) {
        swal('Ups!', 'Silakan pilih provider terlebih dulu!', 'warning')
        return
      }
      let biller_number = this.state.no_pelanggan_postpaid
      let body = {
        session_id: self.props.session,
        product_id: self.state.tx_product_postpaid,
        biller_number: biller_number,
        surcharge : self.state.surcharge
      }
      self.setState({ tx_flag_inquiry_processing: true, tx_tagihan_pelanggan: '', tx_tagihan_prepaid: '' })
      start_time_process_seconds = Math.floor(Date.now() / 1000)
      request
        .post(apis.inquiry)
        .set('Content-Type', 'application/json')
        .timeout(120 * 1000)
        .send(body)
        .end(function (err, res) {
          let message_error = ''
          if (err || !res.ok) {
            util.log(err)
            message_error = { body: err.message, title: 'Transaksi Gagal' }
            if (res !== undefined) {
              message_error.body = 'Error code ' + res.statusCode
            }
          } else {
            util.log(res)
            if (res.statusCode === 200) {
              let payload = res.body
              util.log(payload)
              if (payload.status === 'OK') {
                self.setState({ tx_tagihan_pelanggan: payload.results })
                if (start_time_process_seconds !== 0) {
                  let end_time_process = Math.floor(Date.now() / 1000)
                  let action = config.ga.event.inquiry + '-' + util.time_range_ga(end_time_process - start_time_process_seconds)
                  ReactGA.event({
                    category: 'transaction',
                    action: action,
                    label: payload.results.product_name,
                  })
                }
              } else {
                util.on_error(payload.error)
                message_error = { body: res.body.error.message, title: 'Transaksi Gagal' }
                if (res.body.display_message !== undefined) {
                  message_error.title = res.body.error.message
                  message_error.body = res.body.display_message
                }
              }
            } else {
              message_error = { body: 'HTTP error ' + res.statusCode, title: 'Transaksi Gagal' }
            }
          }
          if (message_error.length !== 0) {
            swal(message_error.title, message_error.body, 'error')
          }
          self.setState({ tx_flag_inquiry_processing: false })
          start_time_process_seconds = 0
        })
    }
  }

  on_btn_bayar_postpaid() {
    this.setState({tx_show_modal_postpaid: true, show_info_pelanggan: false, nama_pelanggan: "", nomor_pelanggan: ""})
  }

  on_btn_bayar_prepaid() {
    if (this.state.no_pelanggan_prepaid.length === 0) {
      swal('Ups!', 'Nomor pelanggan tidak boleh kosong', 'warning')
      return
    }
    if (this.state.tx_product_prepaid.length === 0) {
      swal('Ups!', 'Pilih denom pesanan pelanggan terlebih dulu', 'warning')
      return
    } 
    this.setState({ show_info_pelanggan: false, nama_pelanggan: '', nomor_pelanggan: '' })
    this.setState({tx_show_surcharge: false,tx_show_modal: true, show_info_pelanggan: false, nama_pelanggan: "", nomor_pelanggan: ""})

    this.total_bayar_prepaid()
  }

  on_btn_reset() {
    this.setState({ no_pelanggan_postpaid: '', no_pelanggan_prepaid: '', product_denom_prepaid: [], tx_result_message: '', tx_result_success: '', tx_tagihan_pelanggan: '' })
    tx_product_postpaid = ''
    tx_product_prepaid = ''
  }

  
    
  on_btn_surcharge() {        
    if (this.state.no_pelanggan_postpaid.length !== 0) {

    this.setState({tx_show_modal: false,tx_show_surcharge: true,tx_show_btn_beli:false,tx_tagihan_prepaid:false});        
    }
}


    
on_btn_surcharge_prepaid() {        
  if (this.state.no_pelanggan_prepaid.length !== 0) {

      this.setState({tx_show_modal: false,tx_show_surcharge_prepaid: true,tx_show_btn_beli:false,tx_tagihan_prepaid:false});        
  }
}
  on_btn_confirm_ok_prepaid() {
    if (this.state.tx_flag_processing) return
    if (tx_product_prepaid !== '') {
      let self = this     
      let whitelabel_36 = config.whitelabel.toString(36).toUpperCase()
      let user_id = this.props.profile.userid.toString(36).toUpperCase()
      let prefix = whitelabel_36.substring(whitelabel_36.length - 3)
      let suffix = user_id.substring(user_id.length - 3)
      let token = util.gen_token(prefix, this.props.session, suffix)
      let biller_number = this.state.no_pelanggan_prepaid
      let body = {
        session_id: this.props.session,
        product_id: tx_product_prepaid.product_id,
        product_code: tx_product_prepaid.product_code,
        biller_number: biller_number,
        sign_token: token,
        surcharge:this.state.surchargeprepaid
      }
     
      if (this.state.nama_pelanggan !== undefined && this.state.nama_pelanggan.length !== 0) {
        body.customer_name = this.state.nama_pelanggan
      }
      if (this.state.nomor_pelanggan !== undefined && this.state.nomor_pelanggan.length !== 0) {
        body.customer_phone_number = this.state.nomor_pelanggan
      }
      self.setState({ tx_flag_processing: true })
      start_time_process_seconds = Math.floor(Date.now() / 1000)
      request
        .post(apis.purchase)
        .set('Content-Type', 'application/json')
        .timeout(120 * 1000)
        .send(body)
        .end(function (err, res) {
          let message_result = ''
          let success_result = ''
          if (err || !res.ok) {
            util.log(err)
            message_result = { title: 'Error', body: err.message }
            if (res !== undefined) {
              message_result.body = 'Error code ' + res.statusCode
            }
            if (err.code === 504) {
              message_result.body = config.timeout_message
            }
          } else {
            util.log(res)
            if (res.statusCode === 200) {
              let payload = res.body
              util.log(payload)
              if (payload.status === 'OK') {
                let results = payload.results
                let success_info =
                  'Biller No: ' +
                  results.biller_number +
                  '|Produk: ' +
                  results.product_id +
                  ' ' +
                  results.product_name +
                  '|Harga Produk: Rp. ' +
                  util.rupiah_format(results.nominal) + + "|Biaya Surcharge: Rp. " + util.rupiah_format(results.surcharge)+
                  '|Biaya Admin: Rp. ' +
                  util.rupiah_format(results.fee) +
                  '|Total Bayar: Rp. ' +
                  util.rupiah_format(results.total)
                success_result = { title: 'Transaksi Sukses', body: success_info, data: results }
                self.props.update_balance(results.balance_after)
                swal(success_result.title, payload.display_message, 'success')
                if (start_time_process_seconds !== 0) {
                  let end_time_process = Math.floor(Date.now() / 1000)
                  let action = config.ga.event.purchase + '-' + util.time_range_ga(end_time_process - start_time_process_seconds)
                  ReactGA.event({
                    category: 'transaction',
                    action: action,
                    label: results.product_name,
                  })
                }
              } else {
                util.on_error(payload.error)
                message_result = { title: 'Error', body: res.body.error.message }
                if (res.body.display_message !== undefined) {
                  message_result.body = message_result.body + '. ' + res.body.display_message
                }
              }
            } else {
              message_result = { title: 'Error', body: res.statusCode }
            }
          }
          if (message_result !== null && message_result.length !== 0) {
            swal(message_result.title, message_result.body, 'error')
            message_result.title = '[' + moment().format('DD-MM-YYYY hh:mm') + '] ' + message_result.title
          }
          // , tx_show_modal_prepaid: false
          self.setState({ tx_flag_processing: false, tx_result_message: message_result, tx_result_success: success_result })
          start_time_process_seconds = 0
        })
    }
  }

  on_btn_confirm_ok_postpaid() {
    if (this.state.tx_flag_processing) return
    if (this.state.tx_product_postpaid.length !== 0) {
      let self = this
      let whitelabel_36 = config.whitelabel.toString(36).toUpperCase()
      let user_id = this.props.profile.userid.toString(36).toUpperCase()
      let prefix = whitelabel_36.substring(whitelabel_36.length - 3)
      let suffix = user_id.substring(user_id.length - 3)
      let token = util.gen_token(prefix, this.props.session, suffix)
      let biller_number = this.state.no_pelanggan_postpaid
      let body = {
        session_id: this.props.session,
        product_id: this.state.tx_product_postpaid,
        biller_number: biller_number,
        sign_token: token,
        surcharge:this.state.surcharge
      }
      if (this.state.nama_pelanggan !== undefined && this.state.nama_pelanggan.length !== 0) {
        body.customer_name = this.state.nama_pelanggan
      }
      if (this.state.nomor_pelanggan !== undefined && this.state.nomor_pelanggan.length !== 0) {
        body.customer_phone_number = this.state.nomor_pelanggan
      }
      self.setState({ tx_flag_processing: true })
      start_time_process_seconds = Math.floor(Date.now() / 1000)
      request
        .post(apis.payment)
        .set('Content-Type', 'application/json')
        .timeout(120 * 1000)
        .send(body)
        .end(function (err, res) {
          let message_result = ''
          let success_result = ''
          if (err || !res.ok) {
            util.log(err)
            message_result = { title: 'Error', body: err.message }
            if (res !== undefined) {
              message_result.body = 'Error code ' + res.statusCode
            }
            if (err.code === 504) {
              message_result.body = config.timeout_message
            }
          } else {
            util.log(res)
            if (res.statusCode === 200) {
              let payload = res.body
              util.log(payload)
              if (payload.status === 'OK') {
                let results = payload.results
                let success_info =
                  'Biller No: ' +
                  results.biller_number +
                  '|Produk: ' +
                  results.product_id +
                  ' ' +
                  results.product_name +
                  '|Harga Produk: Rp. ' +
                  util.rupiah_format(results.nominal) +
                  '|Biaya Admin: Rp. ' +
                  util.rupiah_format(results.fee) +
                  '|Total Bayar: Rp. ' +
                  util.rupiah_format(results.total)
                success_result = { title: 'Transaksi Sukses', body: success_info, data: results }
                self.props.update_balance(results.balance_after)
                swal(success_result.title, payload.display_message, 'success')
                self.setState({ tx_tagihan_pelanggan: '' })
                if (start_time_process_seconds !== 0) {
                  let end_time_process = Math.floor(Date.now() / 1000)
                  let action = config.ga.event.payment + '-' + util.time_range_ga(end_time_process - start_time_process_seconds)
                  ReactGA.event({
                    category: 'transaction',
                    action: action,
                    label: results.product_name,
                  })
                }
              } else {
                util.on_error(payload.error)
                message_result = { title: 'Error', body: res.body.error.message }
                if (res.body.display_message !== undefined) {
                  message_result.body = res.body.display_message
                }
                swal(message_result.title, message_result.body, 'error')
              }
            } else {
              message_result = { title: 'Error', body: res.statusCode }
            }
          }
          if (message_result.length !== 0) {
            message_result.title = '[' + moment().format('DD-MM-YYYY hh:mm') + '] ' + message_result.title
          }
          // , tx_show_modal_postpaid: false
          self.setState({ tx_flag_processing: false, tx_result_message: message_result, tx_result_success: success_result })
          start_time_process_seconds = 0
        })
    }
  }

  on_btn_confirm_cancel_postpaid() {
    // this.setState({ tx_show_modal_postpaid: false })
    this.setState({tx_show_modal_postpaid: false,tx_show_surcharge:false,surcharge:0,tx_show_btn_beli:true})

  }

  on_denom_clicked(e) {
    let target = e.currentTarget
    tx_product_prepaid = {
      product_id: target.dataset.product_id,
      product_denom: target.dataset.product,
      product_name: target.dataset.name,
      max_surcharge: target.dataset.max_surcharge,
    }
    util.log('tx_product_prepaid', tx_product_prepaid)
    this.setState({ tx_product_denom_prepaid: target.dataset.product })
    this.nomor_pelanggan_prepaid_input.focus();
    if(target.dataset.max_surcharge > 0 )
      {
          this.setState({tx_show_btn_surcharge: true});
      }
      else
      {
          this.setState({tx_show_btn_surcharge: false,tx_show_btn_beli:true});
      }       

  }

  shouldComponentUpdate(newProps, newState) {
    return true
  }

  total_bayar_prepaid() {
    if (this.state.tx_flag_inquiry_processing) return
    let biller_number = this.state.no_pelanggan_prepaid
    let self = this
    let body = {
      session_id: self.props.session,
      product_id: tx_product_prepaid.product_id,
      product_code: tx_product_prepaid.product_denom,
      biller_number: biller_number,
      surcharge:this.state.surchargeprepaid

    }
    self.setState({ tx_flag_inquiry_processing: true, tx_tagihan_prepaid: '', tx_tagihan_pelanggan: '' })
    request
      .post(apis.inquiry)
      .set('Content-Type', 'application/json')
      .timeout(120 * 1000)
      .send(body)
      .end(function (err, res) {
        let tagihan = ''
        if (err || !res.ok) {
          util.log(err)
          tagihan = '-'
        } else {
          util.log(res)
          if (res.statusCode === 200) {
            let payload = res.body
            if (payload.status === 'OK') {
              let results = payload.results
              tagihan = {
                nominal: 'Rp. ' + util.rupiah_format(results.nominal),
                fee: 'Rp. ' + util.rupiah_format(results.fee),
                total: 'Rp. ' + util.rupiah_format(results.total),
                surcharge : 'Rp. ' + util.rupiah_format(results.surcharge),
                info: results.info,
              }
              if (start_time_process_seconds !== 0) {
                let end_time_process = Math.floor(Date.now() / 1000)
                let action = config.ga.event.inquiry + '-' + util.time_range_ga(end_time_process - start_time_process_seconds)
                ReactGA.event({
                  category: 'transaction',
                  action: action,
                  label: results.product_name,
                })
              }
            } else {
              util.on_error(payload.error)
              tagihan = '-'
              if (payload.display_message.length !== 0) {
                tagihan = payload.display_message
              } else {
                tagihan = payload.error.message
              }
              swal('Error', tagihan, 'error')
            }
          } else {
            tagihan = '-'
          }
        }
        self.setState({ tx_flag_inquiry_processing: false, tx_tagihan_prepaid: tagihan })
        start_time_process_seconds = 0
      })
  }

  on_btn_cetak_tagihan() {
    this.setState({ show_cetak_tagihan: true })
  }

  denoms_view() {
    const item_per_row = 3
    const rows = []
    let self = this
    let product_denom = self.state.product_denom_prepaid
    const items = product_denom.denoms.map((denom, i) => {
      return (
        <a
          data-product={denom.product_code}
          data-name={denom.product_name}
          data-product_id={product_denom.product_id}
          data-max_surcharge={denom.max_surcharge}

          key={i}
          id={'product-item-' + i}
          className="m-nav-grid__item m-nav-grid__item product-item"
          onClick={self.on_denom_clicked.bind(this)}
          style={this.state.tx_product_denom_prepaid === denom.product_code ? { backgroundColor: 'cadetblue', cursor: 'pointer' } : { cursor: 'pointer' }}
        >
          <i className="m-nav-grid__icon flaticon-folder"></i>
          <span
            className="m-nav-grid__text"
            style={this.state.tx_product_denom === denom.product_code ? { color: 'white', fontWeight: 600 } : { color: '#575962', fontWeight: 600 }}
          >
            {denom.product_name}
          </span>
        </a>
      )
    })
    for (let row = 0; row < Math.ceil(product_denom.denoms.length / item_per_row); row += 1) {
      rows.push(
        <div
          key={`row-${row}`}
          className="m-nav-grid__row"
        >
          {items.slice(row * item_per_row, row * item_per_row + item_per_row)}
        </div>
      )
    }
    return rows
  }

  render() {
    let self = this
    let tx_result_success = this.state.tx_result_success.data
    const padding_field = { paddingTop: 2 + 'px', paddingBottom: 2 + 'px' }

    const DenomView = (props) => (
      <div
        style={{ background: '#fff', border: '4px solid #f7f7fa', marginBottom: '18px' }}
        data-code-preview="true"
        data-code-html="true"
        data-code-js="false"
      >
        <div className="m-demo__preview">
          <div className="m-nav-grid">{self.denoms_view()}</div>
        </div>
      </div>
    )

    const TagihanPrepaidView = (props) => (
      <div className="col-lg-6">
        <div
          className="m-portlet m-portlet--success m-portlet--head-solid-bg m-portlet--rounded"
          style={{ minHeight: '500px', background: '#fff' }}
        >
          <div className="m-portlet__head">
            <div className="m-portlet__head-caption">
              <div className="m-portlet__head-title">
                <span className="m-portlet__head-icon">
                  <i className="flaticon-interface-5"></i>
                </span>
                <h3 className="m-portlet__head-text">Tagihan TV PREPAID {this.state.no_pelanggan}</h3>
              </div>
            </div>
          </div>
          <div
            className="m-portlet__body"
            style={{ paddingTop: '10px' }}
          >
            <div className="row">
              <div className="col-lg-12">
                <form>
                  <div
                    className="form-group m-form__group"
                    style={{ marginTop: '15px' }}
                  >
                    <span className="m--font-boldest">Nomor Pelanggan:</span>
                    <p className="form-control-static">{this.state.no_pelanggan_prepaid}</p>
                  </div>
                  <div className="form-group">
                    <span className="m--font-boldest">Produk:</span>
                    <p className="form-control-static">{tx_product_prepaid.length !== 0 ? tx_product_prepaid.product_name : ''}</p>
                  </div>
                  <div className="form-group">
                    <span className="m--font-boldest">Info:</span>
                    <p className="form-control-static">
                      {this.state.tx_tagihan_prepaid.info
                        ? this.state.tx_tagihan_prepaid.info.split('|').map(function (item, key) {
                            return (
                              <span key={key}>
                                {item}
                                <br />
                              </span>
                            )
                          })
                        : '-'}
                    </p>
                  </div>
                  <div
                    className="form-group row"
                    style={{ marginBottom: 0 }}
                  >
                    <div className="col-lg-4">
                      <span className="m--font-boldest">Harga:</span>
                      <p className="form-control-static">{this.state.tx_tagihan_prepaid.length !== 0 ? (this.state.tx_tagihan_prepaid.nominal !== undefined ? this.state.tx_tagihan_prepaid.nominal : '-') : '-'}</p>
                    </div>
                    <div className="col-lg-4">
                      <span className="m--font-boldest">Komisi:</span>
                      <p className="form-control-static">{this.state.tx_tagihan_prepaid.length !== 0 ? (this.state.tx_tagihan_prepaid.fee !== undefined ? this.state.tx_tagihan_prepaid.fee : '-') : '-'}</p>
                    </div>
                  </div>
                  
                  <div className="form-group row" style={{marginBottom: 0}}>

                    <div className="col-lg-4">
                            <span className="m--font-boldest">
                                Biaya Surcharge:
                            </span>
                            <p className="form-control-static">{this.state.tx_tagihan_prepaid.length !== 0 ? (this.state.tx_tagihan_prepaid.surcharge !== undefined ? this.state.tx_tagihan_prepaid.surcharge : '-') : '-'}</p>

                        </div>



                        <div className="col-lg-4">
                          
                    <span className="m--font-boldest">Total Bayar:</span>
                    <div className="form-control-static">
                      {this.state.tx_tagihan_prepaid.length !== 0 ? (
                        this.state.tx_tagihan_prepaid.total !== undefined ? (
                          this.state.tx_tagihan_prepaid.total
                        ) : (
                          this.state.tx_tagihan_prepaid
                        )
                      ) : (
                        <div
                          className="m-loader m-loader--brand"
                          style={{ width: 30 + 'px', display: 'inline-block' }}
                        ></div>
                      )}
                    </div>
                  </div>
                  </div>
                  <div
                    className=""
                    style={{ cursor: 'pointer', textAlign: 'right', marginBottom: '7px' }}
                    onClick={() => {
                      self.setState({ show_info_pelanggan: !self.state.show_info_pelanggan })
                    }}
                  >
                    <i
                      className={this.state.show_info_pelanggan ? 'la la-minus' : 'la la-plus'}
                      style={{ fontSize: '15px', marginRight: '5px' }}
                    ></i>
                    <span className="m--font-boldest">Informasi Pembeli</span>
                    <span style={{ backgroundColor: '#666', height: '8px', marginLeft: '5px' }} />
                  </div>
                  <div
                    className="m-form__section m-form__section--first"
                    style={this.state.show_info_pelanggan ? { display: 'block' } : { display: 'none' }}
                  >
                    <div
                      className="m-form__group row"
                      style={{ marginBottom: '10px' }}
                    >
                      <label
                        className="col-lg-3"
                        style={{ margin: 'auto', fontWeight: 600, textAlign: 'left' }}
                      >
                        Nama:
                      </label>
                      <div className="col-lg-9">
                        <input
                          onChange={(e) => this.on_input_change(e)}
                          type="text"
                          className="form-control m-input"
                          name="nama_pelanggan"
                          value={this.state.nama_pelanggan}
                          placeholder=""
                        />
                      </div>
                    </div>
                    <div
                      className="m-form__group row"
                      style={{}}
                    >
                      <label
                        className="col-lg-3"
                        style={{ margin: 'auto', fontWeight: 600, textAlign: 'left' }}
                      >
                        Nomor HP:
                      </label>
                      <div className="col-lg-9">
                        <input
                          onChange={(e) => this.on_input_change(e)}
                          type="text"
                          className="form-control m-input"
                          name="nomor_pelanggan"
                          value={this.state.nomor_pelanggan}
                          placeholder=""
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className=" m-portlet__foot m-portlet__foot--fit"
                    style={{ marginTop: '20px' }}
                  >
                    <div className="m-form__actions">
                      <div className="row">
                        <p
                          className="m--font-boldest"
                          style={{ marginLeft: 'auto', marginRight: 'auto', marginTop: '10px', textAlign: 'center', fontWeight: 600, fontSize: 'larger' }}
                        >
                          Apakah Anda yakin untuk melanjutkan transaksi ini?
                        </p>
                        <div style={{ margin: 'auto' }}>
                          <button
                            type="button"
                            onClick={this.on_btn_confirm_cancel}
                            className="btn btn-secondary"
                            data-dismiss="modal"
                          >
                            Cancel
                          </button>
                          &nbsp;&nbsp;
                          <button
                             disabled={this.state.tx_button_bayar ? false : true} 
                            type="button"
                            onClick={this.on_btn_confirm_ok_prepaid}
                            className={this.state.tx_flag_processing ? 'btn btn-primary m-loader m-loader--light m-loader--right' : 'btn btn-primary'}
                          >
                            Bayar
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    )

    const SurchargeView = (props) => 
      <div className="col-lg-6">
          <div className="m-portlet m-portlet--success m-portlet--head-solid-bg m-portlet--rounded" style={{minHeight:'500px',background:'#fff'}}>
              <div className="m-portlet__head">
                  <div className="m-portlet__head-caption">
                      <div className="m-portlet__head-title">
                          <span className="m-portlet__head-icon">
                              <i className="flaticon-interface-5"></i>
                          </span>
                          <h3 className="m-portlet__head-text">
                              Input Surcharge 
                          </h3>
                      </div>
                  </div>
              </div>
              <div className="m-portlet__body">
                  <div className="row">
                      <div className="col-lg-12">
                          <form>
                              <div className="form-group m-form__group">
                                  <span className="m--font-boldest">
                                      No Pelanggan:
                                  </span>
                                  <p className="form-control-static">{this.state.no_pelanggan_postpaid}</p>
                              </div>
                              <div className="form-group">
                                  <span className="m--font-boldest">
                                      Produk ID:
                                  </span>
                                  <p className="form-control-static">{tx_product_postpaid.product_id}</p>
                              </div>
                              <div className="form-group">
                                  <span className="m--font-boldest">
                                      Produk:
                                  </span>
                                  <p className="form-control-static">{tx_product_postpaid.product_name}</p>
                              </div>
                               <div className="form-group">
                              <span className="m--font-boldest">Input Surcharge: </span>
                              <input
                                      onChange={(e) => this.on_input_change(e)}
                                      type="number"
                                      className="form-control m-input"
                                      name="surcharge"
                                      value={this.state.surcharge}
                                      placeholder="Input Surcharge"/> 
                              <span class="text-danger small bold">Biaya Surcharge untuk produk ini maksimal Rp.{tx_product_postpaid.max_surcharge} <br/> Biaya Surcharge ini belum termasuk PPH 2%<br/>Biaya Surcharge belum Termasuk PPN 11%</span>
                              <div className="topping">
                                  <label className="m-checkbox m-checkbox--focus" style={{fontWeight: 500,display:'inline-grid',marginLeft:'15px',paddingLeft:'23px'}}>
                                  <input type="checkbox" name="konsumen" id="1" checked={this.state.konsumen} onChange={this.on_grouped_change} />
                                  Konsumen mengetahui dan setuju untuk biaya Surcharge
                                  <span style={{height:'13px',width:'13px'}}></span>
                                  </label>
                                  <label className="m-checkbox m-checkbox--focus" style={{fontWeight: 500,display:'inline-grid',marginLeft:'15px',paddingLeft:'23px'}}>                                            
                                  <input type="checkbox" name="mitra" id="2" checked={this.state.mitra} onChange={this.on_grouped_change} />
                                  Mitra Agen bertanggung jawab atas biaya surcharge pada produk ini                                            <span style={{height:'13px',width:'13px'}}></span>
                                  </label>  
                              </div>                                    
                              </div>
                              <div className=" m-portlet__foot m-portlet__foot--fit" style={{marginTop:'20px'}}>
                                  <div className="m-form__actions">
                                      <div className="row">
                                          <p className="m--font-boldest" style={{marginLeft: 'auto',marginRight: 'auto',marginTop: '10px',textAlign: 'center',fontWeight: 600,fontSize: 'larger'}}>
                                              Apakah Anda yakin untuk melanjutkan transaksi ini?
                                          </p>
                                          <div style={{margin:'auto'}}>
                                              <button type="button" onClick={this.on_btn_confirm_cancel} className="btn btn-secondary" data-dismiss="modal">
                                                  Cancel
                                              </button>
                                              &nbsp;&nbsp;

                                              
                                              <button disabled={this.state.tx_button_ok ? false : true}   onClick={() => this.on_btn_tagihan()}
                          type="button"
                          className={this.state.tx_flag_inquiry_processing ? 'btn btn-success m-loader m-loader--light m-loader--right' : 'btn btn-success'}
                     >
                                                  Cek Tagihan
                                              </button>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </form>
                      </div>
                  </div>
              </div>
          </div>
      </div>

const SurchargePrepaidView = (props) => 
  <div className="col-lg-6">
      <div className="m-portlet m-portlet--success m-portlet--head-solid-bg m-portlet--rounded" style={{minHeight:'500px',background:'#fff'}}>
          <div className="m-portlet__head">
              <div className="m-portlet__head-caption">
                  <div className="m-portlet__head-title">
                      <span className="m-portlet__head-icon">
                          <i className="flaticon-interface-5"></i>
                      </span>
                      <h3 className="m-portlet__head-text">
                          Input Surcharge 
                      </h3>
                  </div>
              </div>
          </div>
          <div className="m-portlet__body">
              <div className="row">
                  <div className="col-lg-12">
                      <form>
                          <div className="form-group m-form__group">
                              <span className="m--font-boldest">
                                  No Pelanggan:
                              </span>
                              <p className="form-control-static">{this.state.no_pelanggan_prepaid}</p>
                          </div>
                          <div className="form-group">
                              <span className="m--font-boldest">
                                  Produk ID:
                              </span>
                              <p className="form-control-static">{tx_product_prepaid.product_id}</p>
                          </div>
                          <div className="form-group">
                              <span className="m--font-boldest">
                                  Produk:
                              </span>
                              <p className="form-control-static">{tx_product_prepaid.product_name}</p>
                          </div>
                           <div className="form-group">
                          <span className="m--font-boldest">Input Surcharge: </span>
                          <input
                                  onChange={(e) => this.on_input_change(e)}
                                  type="number"
                                  className="form-control m-input"
                                  name="surchargeprepaid"
                                  value={this.state.surchargeprepaid}
                                  placeholder="Input Surcharge"/> 
                          <span class="text-danger small bold">Biaya Surcharge untuk produk ini maksimal Rp.{tx_product_prepaid.max_surcharge} <br/> Biaya Surcharge akan dikenakan PPH 2%<br/>Biaya Surcharge belum Termasuk PPN 11%</span>
                          <div className="topping">
                              <label className="m-checkbox m-checkbox--focus" style={{fontWeight: 500,display:'inline-grid',marginLeft:'15px',paddingLeft:'23px'}}>
                              <input type="checkbox" name="konsumen" id="1" checked={this.state.konsumen} onChange={this.on_grouped_change} />
                              Konsumen mengetahui dan setuju untuk biaya Surcharge
                              <span style={{height:'13px',width:'13px'}}></span>
                              </label>
                              <label className="m-checkbox m-checkbox--focus" style={{fontWeight: 500,display:'inline-grid',marginLeft:'15px',paddingLeft:'23px'}}>                                            
                              <input type="checkbox" name="mitra" id="2" checked={this.state.mitra} onChange={this.on_grouped_change} />
                              Mitra Agen bertanggung jawab atas biaya surcharge pada produk ini                                            <span style={{height:'13px',width:'13px'}}></span>
                              </label>  
                          </div>                                    
                          </div>
                          <div className=" m-portlet__foot m-portlet__foot--fit" style={{marginTop:'20px'}}>
                              <div className="m-form__actions">
                                  <div className="row">
                                      <p className="m--font-boldest" style={{marginLeft: 'auto',marginRight: 'auto',marginTop: '10px',textAlign: 'center',fontWeight: 600,fontSize: 'larger'}}>
                                          Apakah Anda yakin untuk melanjutkan transaksi ini?
                                      </p>
                                      <div style={{margin:'auto'}}>
                                          <button type="button" onClick={this.on_btn_confirm_cancel} className="btn btn-secondary" data-dismiss="modal">
                                              Cancel
                                          </button>
                                          &nbsp;&nbsp;
                                          <button disabled={this.state.tx_button_ok ? false : true}  type="button" onClick={this.on_btn_bayar_prepaid}                                               
                                          className={this.state.tx_flag_inquiry_processing ? 'btn btn-success m-loader m-loader--light m-loader--right' : 'btn btn-success'}                                              
                                          >
                                              OK
                                          </button>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </form>
                  </div>
              </div>
          </div>
      </div>
  </div>

    const TagihanPostpaidView = (props) => (
      <div className="col-lg-6">
        <div className="m-portlet m-portlet--success m-portlet--head-solid-bg m-portlet--rounded">
          <div className="m-portlet__head">
            <div className="m-portlet__head-caption">
              <div className="m-portlet__head-title">
                <span className="m-portlet__head-icon">
                  <i className="flaticon-interface-5"></i>
                </span>
                <h3 className="m-portlet__head-text">Tagihan TV POSTPAID {this.state.no_pelanggan}</h3>
              </div>
            </div>
            <div
              className="m-portlet__head-tools"
              style={{ display: 'none' }}
            >
              <ul className="m-portlet__nav">
                <li className="m-portlet__nav-item">
                  <button
                    onClick={() => this.on_btn_bayar_postpaid()}
                    className="m-portlet__nav-link btn btn-primary m-btn m-btn--pill m-btn--air"
                    style={{ padding: '7px 15px' }}
                  >
                    Bayar
                  </button>
                </li>
                <li
                  className="m-portlet__nav-item"
                  style={{ display: 'none' }}
                >
                  <button
                    onClick={() => this.on_btn_cetak_tagihan()}
                    className="m-portlet__nav-link btn btn-light m-btn m-btn--pill m-btn--air"
                    style={{ padding: '7px 15px' }}
                  >
                    Cetak
                  </button>
                </li>
              </ul>
            </div>
          </div>
          <div
            className="m-portlet__body"
            style={{ paddingTop: '10px' }}
          >
            <div className="row">
              <div className="col-lg-12">
                <form className="m-form m-form--fit m-form--label-align-right m-form--group-seperator-dashed">
                  <div
                    className="form-group m-form__group row"
                    style={padding_field}
                  >
                    <label className="col-lg-3 col-form-label">Produk :</label>
                    <div className="col-lg-9">
                      <label className="col-form-label">{tx_product_postpaid.length !== 0 ? this.state.tx_product_postpaid + ' ' + tx_product_postpaid.product_name : this.state.tx_product_postpaid}</label>
                    </div>
                  </div>
                  <div
                    className="form-group m-form__group row"
                    style={padding_field}
                  >
                    <label className="col-lg-3 col-form-label">Info :</label>
                    <div className="col-lg-9">
                      <label className="col-form-label">
                        {this.state.tx_tagihan_pelanggan.info.split('|').map(function (item, key) {
                          return (
                            <span key={key}>
                              {item}
                              <br />
                            </span>
                          )
                        })}
                      </label>
                    </div>
                  </div>
                  <div
                    className="form-group m-form__group row"
                    style={padding_field}
                  >
                    <label className="col-lg-3 col-form-label">Tagihan :</label>
                    <div className="col-lg-9">
                      <label
                        className="col-form-label"
                        style={{ fontWeight: 600 }}
                      >
                        Rp. {this.state.tx_tagihan_pelanggan ? util.rupiah_format(this.state.tx_tagihan_pelanggan.nominal) : '0'}
                      </label>
                    </div>
                  </div>
                  <div
                    className="form-group m-form__group row"
                    style={padding_field}
                  >
                    <label className="col-lg-3 col-form-label">Biaya Admin :</label>
                    <div className="col-lg-9">
                      <label
                        className="col-form-label"
                        style={{ fontWeight: 600 }}
                      >
                        Rp. {this.state.tx_tagihan_pelanggan ? util.rupiah_format(this.state.tx_tagihan_pelanggan.fee) : '0'}
                      </label>
                    </div>
                  </div>
                  <div
                    className="form-group m-form__group row"
                    style={padding_field}
                  >
                    <label className="col-lg-3 col-form-label">Total Bayar :</label>
                    <div className="col-lg-9">
                      <label
                        className="col-form-label"
                        style={{ fontWeight: 600 }}
                      >
                        Rp. {this.state.tx_tagihan_pelanggan ? util.rupiah_format(this.state.tx_tagihan_pelanggan.total) : ''}
                      </label>
                    </div>
                  </div>
                </form>

                <div
                  className=""
                  style={{ cursor: 'pointer', textAlign: 'right', marginBottom: '7px' }}
                  onClick={() => {
                    self.setState({ show_info_pelanggan: !self.state.show_info_pelanggan })
                  }}
                >
                  <i
                    className={this.state.show_info_pelanggan ? 'la la-minus' : 'la la-plus'}
                    style={{ fontSize: '15px', marginRight: '5px' }}
                  ></i>
                  <span className="m--font-boldest">Informasi Pembeli</span>
                  <span style={{ backgroundColor: '#666', height: '8px', marginLeft: '5px' }} />
                </div>

                <div
                  className="m-form__section m-form__section--first"
                  style={this.state.show_info_pelanggan ? { display: 'block' } : { display: 'none' }}
                >
                  <div
                    className="m-form__group row"
                    style={{ marginBottom: '10px' }}
                  >
                    <label
                      className="col-lg-3"
                      style={{ margin: 'auto', fontWeight: 600, textAlign: 'center' }}
                    >
                      Nama:
                    </label>
                    <div className="col-lg-9">
                      <input
                        onChange={(e) => this.on_input_change(e)}
                        type="text"
                        className="form-control m-input"
                        name="nama_pelanggan"
                        value={this.state.nama_pelanggan}
                        placeholder=""
                      />
                    </div>
                  </div>
                  <div
                    className="m-form__group row"
                    style={{}}
                  >
                    <label
                      className="col-lg-3"
                      style={{ margin: 'auto', fontWeight: 600, textAlign: 'center' }}
                    >
                      Telepon:
                    </label>
                    <div className="col-lg-9">
                      <input
                        onChange={(e) => this.on_input_change(e)}
                        type="text"
                        className="form-control m-input"
                        name="nomor_pelanggan"
                        value={this.state.nomor_pelanggan}
                        placeholder=""
                      />
                    </div>
                  </div>
                </div>

                <div
                  className=" m-portlet__foot m-portlet__foot--fit"
                  style={{ marginTop: '20px' }}
                >
                  <div className="m-form__actions">
                    <div className="row">
                      <p
                        className="m--font-boldest"
                        style={{ marginLeft: 'auto', marginRight: 'auto', marginTop: '10px', textAlign: 'center', fontWeight: 600, fontSize: 'larger' }}
                      >
                        Apakah Anda yakin untuk melanjutkan transaksi ini?
                      </p>
                      <div style={{ margin: 'auto' }}>
                        <button
                          type="button"
                          onClick={this.on_btn_confirm_cancel}
                          className="btn btn-secondary"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                        &nbsp;&nbsp;
                        <button
                          disabled={this.state.tx_tagihan_pelanggan.total ? false : true}
                          type="button"
                          onClick={this.on_btn_confirm_ok_postpaid}
                          className={this.state.tx_flag_processing ? 'btn btn-primary m-loader m-loader--light m-loader--right' : 'btn btn-primary'}
                        >
                          Bayar
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )

    return (
      <React.Fragment>
        <div className="row">
          <div className="col-md-12">
            {this.state.tx_result_message.length !== 0 ? (
              <PortletAlert
                icon="warning"
                title={this.state.tx_result_message.title}
                message={this.state.tx_result_message.body}
              />
            ) : (
              ''
            )}
            {this.state.tx_result_success.length !== 0 ? (
              <PortletStruk
                icon="warning"
                title={this.state.tx_result_success.title}
                message={this.state.tx_result_success.body}
                on_btn_cetak_struk={this.on_btn_cetak_struk}
              />
            ) : (
              ''
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6">
            <PortletTab>
              <PortletTabHeader>
                <PortletTabHeaderList
                  id={'pln-postpaid-tab'}
                  title={'TV Postpaid'}
                  icon={' la-align-justify'}
                  active
                />
                <PortletTabHeaderList
                  id={'pln-prepaid-tab'}
                  title={'TV Prepaid'}
                  icon={' la-align-justify'}
                />
              </PortletTabHeader>
              <PortletTabBody>
                <PortletTabBodyList
                  id={'pln-postpaid-tab'}
                  active
                >
                  <Form>
                    <label
                      htmlFor="operator"
                      style={{ fontWeight: 400 }}
                    >
                      Produk
                    </label>
                    <select
                      className="form-control m-input"
                      id="tv-postpaid-sel"
                      value={this.state.tx_product_postpaid}
                      onChange={(e) => this.on_select_change_postpaid(e)}
                    >
                      <option
                        key={999}
                        value=""
                      >
                        {' '}
                        -- Pilih --{' '}
                      </option>
                      {self.state.product_postpaid.length !== 0
                        ? self.state.product_postpaid.map((product, j) => (
                            <option
                              key={j}
                              value={product.product_id}
                              data-product_name={product.product_name}
                              data-max_surcharge={product.max_surcharge}
                            >
                              {product.product_name}
                            </option>
                          ))
                        : ''}
                    </select>
                    <br />
                    <label
                      htmlFor="id-pelanggan"
                      style={{ fontWeight: 400 }}
                    >
                      Nomor Pelanggan
                    </label>
                    <input
                      ref={(input) => {
                        this.nomor_pelanggan_postpaid_input = input
                      }}
                      onChange={(e) => this.on_input_change(e)}
                      onKeyDown={this.on_key_press}
                      type="text"
                      className="form-control m-input"
                      name="no_pelanggan_postpaid"
                      value={this.state.no_pelanggan_postpaid}
                      placeholder="Nomor Pelanggan"
                    />
                    {this.state.tx_product_postpaid == iconnet && (
                      <p
                        className="text-danger font-italic font-weight-bold"
                        style={{ color: '#fc2803' }}
                      >
                        * Kode pelanggan iconnet didapat dari aplikasi PLN Mobile atau email pelanggan
                      </p>
                    )}
                    <br />
                    <br />
                    <div className="row">
                      <div className="col-12">
                      {this.state.tx_show_btn_surcharge_postpaid ? <button onClick={() => this.on_btn_surcharge()} type="button" className="btn btn-primary mr-2">
                                                    Input Surcharge
                                                </button> : ''}

                                                {this.state.tx_show_btn_beli ? 
                        <button
                          onClick={() => this.on_btn_tagihan()}
                          type="button"
                          className={this.state.tx_flag_inquiry_processing ? 'btn btn-success m-loader m-loader--light m-loader--right' : 'btn btn-success'}
                        >
                          Cek Tagihan
                        </button>:''}
                        &nbsp;&nbsp;

                        <button
                          onClick={() => this.on_btn_reset()}
                          type="reset"
                          className="btn btn-secondary"
                        >
                          Reset
                        </button>
                      </div>
                    </div>
                  </Form>
                </PortletTabBodyList>

                <PortletTabBodyList id={'pln-prepaid-tab'}>
                  <Form>
                    <label
                      htmlFor="operator"
                      style={{ fontWeight: 400 }}
                    >
                      Produk
                    </label>
                    <select
                      className="form-control m-input"
                      id="tv-prepaid-sel"
                      value={this.state.tx_product_prepaid}
                      onChange={(e) => this.on_select_change_prepaid(e)}
                    >
                      <option
                        key={999}
                        value=""
                      >
                        {' '}
                        -- Pilih --{' '}
                      </option>
                      {self.state.product_prepaid.length !== 0
                        ? self.state.product_prepaid.map((product, j) => (
                            <option
                              key={j}
                              value={product.product_id}
                            >
                              {product.product_name}
                            </option>
                          ))
                        : ''}
                    </select>
                    <br />
                    {this.state.product_denom_prepaid.length !== 0 ? <DenomView /> : ''}
                    <label
                      htmlFor="id-pelanggan"
                      style={{ fontWeight: 400 }}
                    >
                      Nomor Pelanggan
                    </label>
                    <input
                      ref={(input) => {
                        this.nomor_pelanggan_prepaid_input = input
                      }}
                      onChange={(e) => this.on_input_change(e)}
                      onKeyDown={this.on_key_press}
                      type="text"
                      className="form-control m-input"
                      name="no_pelanggan_prepaid"
                      value={this.state.no_pelanggan_prepaid}
                      placeholder="Nomor Pelanggan"
                    />
                    <br />
                    <br />
                    <div className="row">
                      <div className="col-10">
                      {this.state.tx_show_btn_surcharge ? <button onClick={() => this.on_btn_surcharge_prepaid()} type="button" className="btn btn-primary mr-2">
                                                    Input Surcharge Prepaid
                                                </button> : ''}
                        {this.state.tx_show_btn_beli ? 
                        <button
                          onClick={() => this.on_btn_bayar_prepaid()}
                          type="button"
                          className={this.state.tx_flag_inquiry_processing ? 'btn btn-success m-loader m-loader--light m-loader--right' : 'btn btn-success'}
                        >
                          Bayar
                        </button> : ''}
                        &nbsp;&nbsp;
                        <button
                          onClick={() => this.on_btn_reset()}
                          type="reset"
                          className="btn btn-secondary"
                        >
                          Reset
                        </button>
                      </div>
                    </div>
                  </Form>
                </PortletTabBodyList>
              </PortletTabBody>
            </PortletTab>
          </div>
          {this.state.tx_show_surcharge ? <SurchargeView/> : ''}
          {this.state.tx_show_surcharge_prepaid ? <SurchargePrepaidView/> : ''}
          {this.state.tx_tagihan_pelanggan ? <TagihanPostpaidView /> : ''}
          {this.state.tx_tagihan_prepaid ? <TagihanPrepaidView /> : ''}
          {this.state.tx_show_struk ? (
            <CetakStruk
              data={tx_result_success}
              on_close={() => {
                self.setState({ tx_show_struk: false })
              }}
            />
          ) : (
            ''
          )}
          {this.state.show_cetak_tagihan ? (
            <CetakTagihan
              data={{
                product_id: this.state.tx_tagihan_pelanggan.product_id,
                product_name: this.state.tx_tagihan_pelanggan.product_name,
                biller_number: this.state.no_pelanggan_postpaid,
                info: this.state.tx_tagihan_pelanggan.info,
                nominal: util.rupiah_format(this.state.tx_tagihan_pelanggan.nominal),
                fee: util.rupiah_format(this.state.tx_tagihan_pelanggan.fee),
                surcharge: util.rupiah_format(this.state.tx_tagihan_pelanggan.surcharge),
                total: util.rupiah_format(this.state.tx_tagihan_pelanggan.total),
                on_cetak_close: () => {
                  self.setState({ show_cetak_tagihan: false })
                },
              }}
            />
          ) : (
            ''
          )}
        </div>
      </React.Fragment>
    )
  }
}

const mapDispatchToProps = (dispatch) => ({
  update_current_page: (val) => dispatch(do_action(action.ACTION_UPDATE_CURRENT_PAGE, val)),
  update_balance: (val) => dispatch(do_action(action.ACTION_UPDATE_USER_BALANCE, val)),
})

const mapStateToProps = (state) => ({
  session: state.Session,
  profile: state.Profile,
})

export default connect(mapStateToProps, mapDispatchToProps)(AgentTV)
