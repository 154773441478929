import React, {Component} from 'react'
import { connect } from 'react-redux'
import {Form, FormGroup} from '../component/form/form'
import {Portlet, PortletHead, PortletBody, PortletFooter} from '../component/widget/portlet'
import api from '../helper/api'
import localdata from '../helper/localdata'
import config from '../helper/config'
import swal from 'sweetalert'
import action from '../helper/action'
import do_action from '../action'

class TopupVA extends Component {
    constructor(props) {
        super(props)
    }

    componentDidMount() {
        this.scroll_to_top()
        this.props.update_current_page('topup')
    }

    scroll_step() {
        if (window.pageYOffset === 0) {
            clearInterval(this.state.scroll_interval_id);
        }
        window.scroll(0, window.pageYOffset - 50);
    }

    scroll_to_top() {
        let scroll_interval_id = setInterval(this.scroll_step.bind(this), 16.66);
        this.setState({scroll_interval_id: scroll_interval_id});
    }

    render() {
        const container_style = {marginBottom: '30px'}
        const title_style = {fontWeight: 600,marginBottom: '15px',fontSize: '18px'}
        const content_style = {fontWeight: 500}
        const image_style = {marginLeft:'auto',marginRight:'auto',maxWidth: 200+'px',marginBottom: 20+'px',display: 'table-cell'}
        return (
            <div>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="m-portlet">
                            <div className="m-portlet__body">
                                <article className="ac-text">
                                    <img src="../swipe/img/pos.png" className="gopay-patform-icon" style={image_style}/>   
                                    <div style={container_style}>
                                        <label style={title_style}>
                                            Loket POS INDONESIA
                                        </label>
                                        <article>
                                            <ol>
                                                <li style={content_style}><p>Kunjungi loket Pos Indonesia terdekat dan dapatkan layanan TOP UP POSFIN</p></li>
                                                <li style={content_style}><p>Infokan besaran Top Up POSFIN yang akan anda isi dan nomor rekening pos anda (contoh : 111********) 11 Digit kepada petugas loket Pos</p></li>
                                                <li style={content_style}><p>Pastikan Nomor Rekening Pos dan Nama yang tercetak pada struk sudah sesuai dengan profile POSFIN anda</p></li>
                                                <li style={content_style}><p>Simpan struk bukti Top Up POSFIN</p></li>
                                            </ol>
                                            <p className="text-danger" style={{fontSize: 'smaller'}}>Note :
                                            <br/>- Untuk mengetahui nomor rekening POS, anda dapat membuka menu profile pada aplikasi POSFIN
                                            <br/>- Jumlah Top Up minimum Rp. 10.000
                                            <br/>- Biaya admin Rp. 0
                                            </p>
                                        </article>
                                    </div>
                                    
                                </article>
                            </div>
                        </div>
                    </div>

                    

                </div>

                <div className="row">
                <div className="col-lg-6">
                        <div className="m-portlet">
                            <div className="m-portlet__body">
                                <article className="ac-text">
                                    <img src="../swipe/img/bca.jpg" className="gopay-patform-icon" style={image_style}/>   
                                    <div style={container_style}>
                                        <label style={title_style}>
                                            ATM BCA
                                        </label>
                                        <article>
                                            <ol>
                                                <li style={content_style}><p>Masukan kartu ATM dan PIN anda</p></li>
                                                <li style={content_style}><p>Masuk ke menu transaksi lainnya, pilih Transfer</p></li>
                                                <li style={content_style}><p>Pilih rekening tabungan</p></li>
                                                <li style={content_style}><p>Pilih ke rekening BCA virtual account</p></li>
                                                <li style={content_style}><p>Masukkan nomer rekening virtual account anda (contoh:81610111xxxxxxxx) 16 Digit</p></li>
                                                <li style={content_style}><p>Konfirmasi data dan Masukkan jumlah top up atau transfer yang diinginkan, kemudian tekan BENAR</p></li>
                                                <li style={content_style}><p>Konfirmasi transaksi, kemudian tekan YA untuk melanjutkan transaksi</p></li>
                                                <li style={content_style}><p>Mohon pastikan No Rekening Virtual Account (VA) dan nama sudah sesuai dengan profile anda</p></li>
                                            </ol>
                                            <p className="text-danger" style={{fontSize: 'smaller'}}>Note :
                                            <br/>- Anda dapat membuka menu profile anda di aplikasi PosFIN untuk mengetahui nomor rekening Virtual Account BCA anda
                                            <br/>- Nilai Top Up minimum Rp.10.000
                                            <br/>- Biaya admin Rp.2500
                                            <br/>- Biaya admin dipotong dari nominal Top Up yang anda lakukan
                                            </p>
                                        </article>
                                    </div>

                                    <div style={container_style}>
                                        <label style={title_style}>
                                            INTERNET BANKING (KLIK BCA)
                                        </label>
                                        <article>
                                            <ol>
                                                <li style={content_style}><p>Masuk kedalam website klik BCA (https://ibank.klikbca.com/login.jsp)</p></li>
                                                <li style={content_style}><p>Login klik BCA</p></li>
                                                <li style={content_style}><p>Silahkan masukkan PIN Internat Banking anda, kemudian tekan (login)</p></li>
                                                <li style={content_style}><p>Pilih menu transaksi (Transfer Dana)</p></li>
                                                <li style={content_style}><p>Pilih transaksi (Transfer ke BCA virtual account)</p></li>
                                                <li style={content_style}><p>Input nomor virtual account BCA (contoh : 81610111********)16 Digit, kemudian tekan (lanjutkan)</p></li>
                                                <li style={content_style}><p>Konfirmasi data dan isi nominal transfer, kemudian tekan (lanjutkan)</p></li>
                                                <li style={content_style}><p>Masukkan (respone key BCA appli 1), kemudian tekan (kirim)</p></li>
                                                <li style={content_style}><p>Konfirmasi transaksi telah selesai di proses dan transaksi telah berhasil</p></li>
                                                <li style={content_style}><p>Struk bukti transaksi dapat di cetak atau disimpan</p></li>
                                                <li style={content_style}><p>Mohon pastikan No Rekening VA dan Nama sudah sesuai dengan profile anda</p></li>
                                            </ol>
                                            <p className="text-danger" style={{fontSize: 'smaller'}}>Note :
                                            <br/>- Anda dapat membuka menu profile anda di aplikasi PosFIN untuk mengetahui nomor rekening Virtual Account BCA anda
                                            <br/>- Nilai Top Up minimum Rp.10.000
                                            <br/>- Biaya admin Rp.2500
                                            <br/>- Biaya admin dipotong dari nominal Top Up yang anda lakukan
                                            </p>
                                        </article>
                                    </div>
                                    
                                    <div style={container_style}>
                                        <label className="ac-label" style={{fontWeight:600}}>
                                            Mobile Banking (m-BCA)
                                        </label>
                                        <article className="ac-sub-text">
                                            <ol className="custom-bullet custom-bullet--a">
                                                <li style={content_style}><p>Login M-BCA (BCA MOBILE)</p></li>
                                                <li style={content_style}><p>Pilih M-Transfer > Pilih transaksi BCA Virtual Account</p></li>
                                                <li style={content_style}><p>Input nomor rek VA BCA anda (contoh : 81610111********)16 Digit, Kemudian tekan OK</p></li>
                                                <li style={content_style}><p>Masukkan jumlah nominal transfer</p></li>
                                                <li style={content_style}><p>Konfirmasi nomor rekening VA BCA, Kemudian tekan SEND</p></li>
                                                <li style={content_style}><p>Masukkan PIN M-BCA, Kemudian tekan OK</p></li>
                                                <li style={content_style}><p>Mohon pastikan No Rekening VA dan Nama sudah sesuai dengan profile anda</p></li>
                                            </ol>
                                            <p className="text-danger" style={{fontSize: 'smaller'}}>Note :
                                            <br/>- Anda dapat membuka menu profile anda di aplikasi PosFIN untuk mengetahui nomor rekening Virtual Account BCA anda
                                                - Nilai Top Up minimum Rp.10.000
                                                - Biaya admin Rp.2500
                                                - Biaya admin dipotong dari nominal Top Up yang anda lakukan
                                            </p>
                                        </article>
                                    </div>
                                </article>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="m-portlet">
                            <div className="m-portlet__body">
                                <article className="ac-text">
                                    <img src="../swipe/img/mandiri.png" className="gopay-patform-icon" style={image_style}/>   
                                    <div style={container_style}>
                                        <label style={title_style}>
                                            Melalui ATM Mandiri
                                        </label>
                                        <article>
                                            <ol>
                                                <li style={content_style}><p>Masukkan kartu ATM Mandiri Anda</p></li>
                                                <li style={content_style}><p>Pilih Bahasa (contoh:Indonesia)</p></li>
                                                <li style={content_style}><p>Input nomor PIN ATM Mandiri</p></li>
                                                <li style={content_style}><p>Pilih menu Bayar/Beli</p></li>
                                                <li style={content_style}><p>Pilih menu Lainnya</p></li>
                                                <li style={content_style}><p>Pilih lagi menu Lainnya</p></li>
                                                <li style={content_style}><p>Masukkan Kode Perusahaan (88588)</p></li>
                                                <li style={content_style}><p>Masukkan 16 Digit nomor VA Mandiri Agen (contoh: 88588111********)</p></li>
                                                <li style={content_style}><p>Konfirmasi daftar transfer, klik BENAR</p></li>
                                                <li style={content_style}><p>Konfirmasi total jumlah transfer, klik BENAR</p></li>
                                                <li style={content_style}><p>Konfirmasi transaksi berhasil</p></li>
                                                <li style={content_style}><p>Bukti Pembayaran dalam bentuk STRUK agar disimpan sebagai bukti pembayaran yang sah dari Bank Mandiri</p></li>
                                                <li style={content_style}><p>Mohon pastikan Nomor Rekening VA dan Nama sudah sesuai dengan profile Anda</p></li>
                                            </ol>
                                            <p className="text-danger" style={{fontSize: 'smaller'}}>Note :
                                            <br/>- Anda dapat melihat menu profile PosFin anda untuk mengetahui nomor rekening VA mandiri anda.
                                            <br/>- Nilai top up minimal Rp. 50,000
                                            <br/>- Biaya admin Rp.500 (Biaya admin terpisah dengan jumlah transaksi top up)

                                            </p>
                                        </article>
                                    </div>

                                    <div style={container_style}>
                                        <label style={title_style}>
                                            Melalui Internet Banking
                                        </label>
                                        <article>
                                            <ol>
                                                <li style={content_style}><p>Masuk ke Internet Banking Mandiri dengan alamat alamat https://ibank.bankmandiri.co.id kemudian masukkan User ID dan Password, setelah itu klik “Masuk”</p></li>
                                                <li style={content_style}><p>Pilih “Pembayaran” kemudian pilih “Multipayment”</p></li>
                                                <li style={content_style}><p>Pada kolom Penyedia Jasa pilih “Pos Indonesia”</p></li>
                                                <li style={content_style}><p>Pada Nomor MVA klik “Pilih nama pembayaran”</p></li>
                                                <li style={content_style}><p>Masukkan nomor Vitual Account Bank Mandiri Agen kemudian klik “Tambah Sebagai Nomor Baru”. Nomor Virtual Account Bank Mandiri Agen akan tersimpan di system Internet Banking Mandiri, jadi jika nanti agen akan top up via Internet Banking Mandiri lagi maka tidak perlu Tambah Sebagai Nomor Baru lagi</p></li>
                                                <li style={content_style}><p>Kemudian isi “Nama Pembayaran” setelah itu klik tombol “Konfirmasi”</p></li>
                                                <li style={content_style}><p>Masukkan jumlah nominal yang akan di top up pada kolom “Nominal” kemudian klik “Lanjut”</p></li>
                                                <li style={content_style}><p>Muncul menu Konfirmasi Transaksi, cek kembali dan jika sesuai klik tombol “Konfirmasi”. Ikuti proses selanjutnya sampai mendapat resi bukti transfer</p></li>
                                            </ol>
                                            <p className="text-danger" style={{fontSize: 'smaller'}}>Note :
                                                <br/>- Anda dapat melihat menu profile PosFin anda untuk mengetahui nomor rekening VA mandiri anda.
                                                <br/>- Nilai top up minimal Rp. 50,000
                                                <br/>- Biaya admin Rp.500 (Biaya admin terpisah dengan jumlah transaksi top up)
                                            </p>
                                        </article>
                                    </div>

                                    <div style={container_style}>
                                        <label style={title_style}>
                                            Melalui Mobile Banking
                                        </label>
                                        <article>
                                            <ol>
                                                <li style={content_style}><p>Akses Mandiri Mobile Banking dari handphone kemudian masukkan user ID dan password setelah itu klik “Login”</p></li>
                                                <li style={content_style}><p>Pilih “Bayar”</p></li>
                                                <li style={content_style}><p>Jika Agen baru pertama kali melakukan Top Up menggunakan mobile Banking Mandiri maka klik “Buat Pembayaran Baru”</p></li>
                                                <li style={content_style}><p>Pilih “Multipayment”</p></li>
                                                <li style={content_style}><p>Klik kolom “Penyedia Jasa” kemudian pilih “Pos Indonesia”</p></li>
                                                <li style={content_style}><p>Klik kolom “Nomor MVA” kemudian masukkan VA pada kolom “Nomor MVA”. Jika sebelumnya belum pernah Top Up melalui mBanking Mandiri maka klik “Tambah Sebagai Nomor Baru”</p></li>
                                                <li style={content_style}><p>Masukkan nama pembayaran pada kolom “Nama Pembayaran” kemudian klik “Konfirmasi”</p></li>
                                                <li style={content_style}><p>Masukkan jumlah nominal Top Up kemudian klik “Lanjut”</p></li>
                                                <li style={content_style}><p>Muncul menu Konfirmasi, jika sudah sesuai klik tombol “Konfirmasi”</p></li>
                                                <li style={content_style}><p>Langkah terakhir masukkan MPIN, setelah itu akan muncul Konfirmasi transaksi berhasil dan saldo pada aplikasi POSFIN akan bertambah</p></li>
                                            </ol>
                                            <p className="text-danger" style={{fontSize: 'smaller'}}>Note :
                                                <br/>- Anda dapat melihat menu profile POSFIN anda untuk mengetahui nomor rekening VA Mandiri Anda.
                                                <br/>- Nilai top up minimal Rp. 50,000
                                                <br/>- Biaya admin Rp.500 (Biaya admin terpisah dengan jumlah transaksi top up)
                                            </p>
                                        </article>
                                    </div>
                                    
                                </article>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => ({
    update_current_page:  val => dispatch(do_action(action.ACTION_UPDATE_CURRENT_PAGE, val))
})

const mapStateToProps = (state) => ({
    profile: state.Profile
})

export default connect(mapStateToProps, mapDispatchToProps)(TopupVA)