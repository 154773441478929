import React, { Component } from 'react'
import {
  Link
} from "react-router-dom";

export class Portlet extends Component {
    render() {
        return (
            <div className="m-portlet m-portlet--tab">
                {this.props.children}
            </div>
        )
    }
}

export class PortletHead extends Component {
    render() {
        return (
            <div className="m-portlet__head">
                <div className="m-portlet__head-caption">
                    <div className="m-portlet__head-title">
                        <span className="m-portlet__head-icon m--hide">
                            <i className="la la-gear"></i>
                        </span>
                        <h3 className="m-portlet__head-text">
                            {this.props.headTxt}
                            {this.props.smallTxt && <small>{this.props.smallTxt}</small>}
                        </h3>
                    </div>
                </div>
            </div>
        )
    }
}

export class PortletBody extends Component {
    render() {
        return (
            <div className="m-portlet__body">
                {this.props.children}
            </div>
        )
    }
}

export class PortletFooter extends Component {
    render() {
        return (
            <div className=" m-portlet__foot m-portlet__foot--fit ">
                {this.props.children}
            </div>
        )
    }
}

export class PortletTab extends Component {
    render() {
        return (
            <div className="m-portlet m-portlet--tabs">
                {this.props.children}
            </div>
        )
    }
}

export class PortletTabHeader extends Component {
    render() {
        return (
            <div className="m-portlet__head">
                <div className="m-portlet__head-tools">
                    <ul className="nav nav-tabs m-tabs-line m-tabs-line--primary m-tabs-line--2x" role="tablist">
                        {this.props.children}
                    </ul>
                </div>
            </div>
        )
    }
}

export class PortletTabHeaderList extends Component {
    render() {
        return (
            <li className="nav-item m-tabs__item">
                <a className={this.props.active ? 'nav-link m-tabs__link active' : 'nav-link m-tabs__link'} data-toggle="tab" href={'#' + this.props.id} role="tab">
                    <i className={this.props.icon ? 'la la-' + this.props.icon : 'la la-circle'}></i>
                    {this.props.title ? this.props.title : "missing title props"}
                </a>
            </li>
        )
    }
}

export class PortletTabBody extends Component {
    render() {
        return (
            <div className="m-portlet__body">
                <div className="tab-content">
                    {this.props.children}
                </div>
            </div>
        )
    }
}

export class PortletTabBodyList extends Component {
    render() {
        return (
            <div className={this.props.active ? 'tab-pane active' : 'tab-pane'} id={this.props.id} role="tabpanel">
                {this.props.children}
            </div>
        )
    }
}

export class PortletAlert extends Component {
    render() {
        return (
            <Portlet>
                <div className="m-alert m-alert--icon m-alert--air m-alert--square alert alert-dismissible fade show" role="alert">
                    <div className="m-alert__icon">
                        <i className={this.props.icon ? "la la-" + this.props.icon : "la la-warning"}></i>
                    </div>
                    <div className="m-alert__text">
                        <strong>
                            {this.props.title}
                        </strong>
                        <p style={{whiteSpace: 'pre-line'}}>{this.props.message}</p>
                    </div>
                    <div className="m-alert__close">
                        <button type="button" className="close" data-dismiss="alert" aria-label="Close"></button>
                    </div>
                </div>
            </Portlet>
        )
    }
}

export class PortletStruk extends Component {
    render() {
        return (
            <div className="m-portlet m-portlet--success m-portlet--head-solid-bg m-portlet--rounded">
                <div className="m-portlet__head">
                    <div className="m-portlet__head-caption">
                        <div className="m-portlet__head-title">
                            <span className="m-portlet__head-icon">
                                <i className="flaticon-calendar"></i>
                            </span>
                            <h3 className="m-portlet__head-text">
                                {this.props.title}
                            </h3>
                        </div>
                    </div>
                    <div className="m-portlet__head-tools">
                        <ul className="m-portlet__nav">
                            <li className="m-portlet__nav-item">
                                <button onClick={(e) => this.props.on_btn_cetak_struk(e)} className="m-portlet__nav-link btn btn-light m-btn m-btn--pill m-btn--air">
                                    Detil & Cetak
                                </button>
                            </li>
                            <li className="m-portlet__nav-item m-dropdown m-dropdown--inline m-dropdown--arrow m-dropdown--align-right m-dropdown--align-push" data-dropdown-toggle="hover">
                                <Link to="/agen/riwayat-transaksi" className="m-portlet__nav-link m-dropdown__toggle btn btn-light m-btn m-btn--pill m-btn--air">
                                    Riwayat Traksaksi
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="m-portlet__body" style={{textAlign:'center',display:'none'}}>
                    {this.props.message.split('|').map(function(item, key) {
                        return (
                            <span key={key}>
                                {item}
                                <br/>
                            </span>
                        )
                    })}
                </div>
            </div>
        )
    }
}

export class PortletPending extends Component {
    render() {
        return (
            <div className="m-portlet m-portlet--success m-portlet--head-solid-bg m-portlet--rounded">
                <div className="m-portlet__head">
                    <div className="m-portlet__head-caption">
                        <div className="m-portlet__head-title">
                            <span className="m-portlet__head-icon">
                                <i className="flaticon-calendar"></i>
                            </span>
                            <h3 className="m-portlet__head-text">
                                {this.props.title}
                            </h3>
                        </div>
                    </div>
                    <div className="m-portlet__head-tools">
                        <ul className="m-portlet__nav">
                            <li className="m-portlet__nav-item">
                                <button onClick={(e) => this.props.on_btn_cek_status(e)}  className={this.props.buttonloading ? 'm-portlet__nav-link btn btn-primary m-btn m-btn--pill m-btn--air m-loader m-loader--light m-loader--right' : 'm-portlet__nav-link btn btn-primary m-btn m-btn--pill m-btn--air'} id="buttoncekstatus">
                                    Cek Status
                                </button>
                            </li>
                            <li className="m-portlet__nav-item m-dropdown m-dropdown--inline m-dropdown--arrow m-dropdown--align-right m-dropdown--align-push" data-dropdown-toggle="hover">
                                <Link to="/agen/riwayat-transaksi" className="m-portlet__nav-link m-dropdown__toggle btn btn-light m-btn m-btn--pill m-btn--air">
                                    Riwayat Traksaksi
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="m-portlet__body" style={{textAlign:'center',display:'none'}}>
                    {this.props.message.split('|').map(function(item, key) {
                        return (
                            <span key={key}>
                                {item}
                                <br/>
                            </span>
                        )
                    })}
                </div>
            </div>
        )
    }
}